import { Input } from 'antd';
import React from "react";
import styled from "styled-components";
import { Company } from "../types"

const StyledInput = styled(Input)`
    span.ant-input-group-addon {
      width: 140px
    }
`
type Prop = {
    companyForm: Company;
    formChange: Function;
}

const CompanyModal : React.FC<Prop> = (props: Prop) => {
    const handleOnChange = (event: any, type: 'name') => {
        const value = event.target.value;
        props.formChange({ ...props.companyForm, [type]: value });
      };
    return (
        <div>
            <StyledInput
                addonBefore={'name'}
                value={props.companyForm?.name}
                onChange={(event) => handleOnChange(event, 'name')}
                style={{marginBottom: 6}}
            />
        </div>
    );
}

export default CompanyModal;