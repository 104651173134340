import { Company } from '@/pages/Company/types';
import AppTable from '@/pages/components/AppTable';
import React from 'react';

type Prop = {
  total: number;
  page: number;
  pageSize: number;
  data: Record<string, any>[];
  selectedRowKeys?: React.Key[];
  setPagination: Function;
  setSelectedRowKeys: Function;
};

const TagTable = (props: Prop) => {
  const columns = [
    { title: 'ID', dataIndex: 'id' },
    {
      title: 'NAME',
      dataIndex: 'name',
      editable: true,
    },
    { title: 'SLUG', dataIndex: 'slug', editable: true },
    { title: 'CATEGORY', 
      dataIndex: 'categoryString',
      editable: true,
    },
  ];

  const setSelectedRowKeys = (keys: number[]) => {
    props.setSelectedRowKeys(keys);
  };

  return (
    <div>
      <AppTable
        columns={columns}
        data={props.data}
        page={props.page}
        total={props.total}
        pageSize={props.pageSize}
        selectedRowKeys={props.selectedRowKeys}
        setPagination={props.setPagination}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </div>
  );
};

export default TagTable;
