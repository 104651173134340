import axios from 'axios';

export const getUsers = async (filter: any) => {
  try {
    const result = await axios.get('/AppUsers', {
      params: {
        filter,
      },
    });
    return result?.data || [];
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserCount = async (filter: any) => {
  try {
    const result = await axios.get('/AppUsers/count', {
      params: {
        where: filter.where,
      },
    });
    return result?.data?.count || 0;
  } catch (error) {
    throw new Error(error);
  }
};

export const postUser = async (user: any) => {
  try {
    const { data } = await axios.post('/AppUsers', user);
    return data;
  } catch (error) {
    return {
      error: error?.response?.data?.error?.message
    };
  }
};

export const patchUser = async (user: any) => {
  try {
    const { data } = await axios.patch('/AppUsers', user);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteUser = async (id: number) => {
  try {
    const { data } = await axios.delete('/AppUsers/' + id);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};
