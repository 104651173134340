import React, { useState } from 'react';
import { Form, Table } from 'antd';

type Column = {
  title: string;
  dataIndex: string;
  editable?: boolean;
  width?: string;
};

type Prop = {
  columns: Column[];
  page: number;
  total: number;
  pageSize: number;
  data: Record<string, any>[];
  setPagination: Function;
  selectedRowKeys?: React.Key[];
  setSelectedRowKeys?: Function;
};

const AppTable = (props: Prop) => {
  const [form] = Form.useForm();

  const mergedColumns = props.columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return col;
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (props.setSelectedRowKeys) {
      props.setSelectedRowKeys(newSelectedRowKeys);
    }
  };

  const rowSelection = {
    selectedRowKeys: props.selectedRowKeys || [],
    onChange: onSelectChange,
  };

  return (
    <Form form={form} component={false}>
      <Table
        rowKey="id"
        bordered
        dataSource={props.data}
        columns={mergedColumns}
        rowClassName="editable-row"
        rowSelection={rowSelection}
        pagination={{
          current: props.page,
          pageSize: props.pageSize,
          total: props.total,
          onChange: (page, pageSize) => {
            props.setPagination({ page, pageSize });
          },
        }}
        // scroll={{ y: 840, x: 4000 }}
      />
    </Form>
  );
};

AppTable.defaultProps = {
  columns: [],
};

export default AppTable;
