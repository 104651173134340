import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from '@/routes'
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
const App: React.FC = () => {
  return useRoutes(routes)
};

export default App;
