export const getFilterStorage = () => {
    const storage = localStorage.getItem('filterStorage');
    return JSON.parse(storage || '{}');
}

export const setFilterStorage = (data: Record<string, any>) => {
    const storage = localStorage.getItem('filterStorage');
    const result = JSON.parse(storage || '{}');
    const temp = {
        ...result,
        ...data,
    };
    localStorage.setItem('filterStorage', JSON.stringify(temp));
}

export const clearStorage = () => {
    localStorage.clear();
}