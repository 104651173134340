export const getUniqueListBy = (arr: any[] = [], keys: string[] = []) => arr.filter((v, i, a) => a.findIndex(v2 => keys.every(k => v2[k] === v[k])) === i)

export const shuffleArray = (array: Record<string, any>[]): Record<string, any>[] => {
    let currentIndex = array.length;
    let randomIndex = -1;
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
    return array;
}
