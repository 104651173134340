import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
type Prop = {
    data: string;
    onChange: Function;
  };
const MyEditor = (prop:Prop) => {
//   const [content, setContent] = useState("<pre>"+prop.data+"</pre>");
let dataWithNewlines: any;
if(prop.data.includes('</div>')){
  // nếu data chứ thẻ </div> thì không replace
  dataWithNewlines = prop.data;
}else{
  dataWithNewlines = prop.data.replace(/\n/g, '<br>');
//   dataWithNewlines = prop.data.replace(/\r\n/g, '<br>')
//   .replace(/(<br>)(\d+\.\s.*?)(<br>)/g, '$1<strong>$2</strong>$3')
//   .replace(/(<br>)(•\s.*?)(<br>)/g, '$1<strong>$2</strong>$3');

//   // Thay thế các ký tự xuống dòng
//   dataWithNewlines = prop.data.replace(/\r\n/g, '<br>');

// // In đậm các dòng có số thứ tự (dạng số và chữ số La Mã)
// // dataWithNewlines = dataWithNewlines.replace(/(<br>)(\d+\.\s.*?)(<br>)/g, '$1<strong>$2</strong>$3');
//   dataWithNewlines = dataWithNewlines.replace(/(<br>)(\d+\.\s.*?)(<br>)/g, '$1<strong>$2</strong>$3')
//   .replace(/(<br>)([IVXLCDM]+\.\s.*?)(<br>)/g, '$1<strong>$2</strong>$3');
// // Kiểm tra nếu đã có dòng in đậm số thứ tự thì không in đậm bullet nữa
// if (!/<strong>\d+\.\s.*?<\/strong>/.test(dataWithNewlines)) {
//   dataWithNewlines = dataWithNewlines.replace(/(<br>)(•\s.*?)(<br>)/g, '$1<strong>$2</strong>$3');
// }

}
  const [content, setContent] = useState(dataWithNewlines);
    console.log('viet prop',prop);
  return (
    <Editor
      apiKey="t5hau2ir5mxswxlpmoehq7imjd4sfm507sw81n15cnalhiis" // Get your API key from TinyMCE
    //   initialValue={"<pre>"+content+"</pre>"}
      initialValue={content}
      init={{
        height: 500,
        menubar: false,
        // setup: function (editor) {
        //   editor.on('change', function () {
        //     console.log('viet change');
        //   editor.setContent(editor.getContent().replace(/<p>&nbsp;<\/p>/g, ''));
        //   });
        // },
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "link","code",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | \
alignleft aligncenter alignright alignjustify | \
bullist numlist outdent indent | removeformat | help | link | code",
      }}
      onEditorChange={newContent=> prop.onChange(newContent)}
    />
  );
};

export default MyEditor;
