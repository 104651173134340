import * as XLSX from "xlsx";

const excel2Json = (e: Event): Promise<string[][]> => {
    return new Promise((resolve) => {
        if (!(e.target instanceof HTMLInputElement)) {
            return;
        }
        const [file] = e?.target?.files as FileList;
        const reader = new FileReader();
        reader.onload = (evt: any) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 }) as string[][];
            resolve(data);
        };
        reader.readAsBinaryString(file);
    });
};

export default excel2Json;