import React from 'react'

import type {TabsProps} from 'antd'
import DoSpaceToExcel from '@/pages/Tools/components/do-space-to-excel'
import BulkDeletion from './components/bulk-deletion'
import {Tabs} from 'antd'
import BulkUpsert from './components/bulk-upsert'

type Props = {};

const items: TabsProps['items'] = [
	{
		key:      'do-space-to-excel',
		label:    `DO Space To Excel`,
		children: <DoSpaceToExcel/>,
	},
	{
		key:      'bulk-deletion',
		label:    `Bulk Deletion`,
		children: <BulkDeletion/>,
	},
	{
		key:      'bulk-upsert',
		label:    `Bulk Upsert`,
		children: <BulkUpsert/>,
	},
]

const ToolsPage = (props: Props) => {
	return (<>
		<Tabs defaultActiveKey={'do-space-to-excel'} items={items}/>
	</>)
}

export default ToolsPage
