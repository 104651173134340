import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  postContent,
  upsertAllCategories,
} from '../../services/content-hub-services';
import { CategoriesInput, ContentHub } from '../../types';
import excel2Json from '../../util/excel2json';
import EditTable from './components/table';
import { Progress, Spin, notification } from 'antd';
import { getUniqueListBy } from '../../util/handle-array';
import { stringToSlug } from '@/util/handle-string';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

const FillIn = () => {
  const navigate = useNavigate();

  let allowCreateCateTag: boolean = false;
  const [contentHub, setContentHub] = useState<ContentHub[]>([]);
  const [progress, setProgress] = useState<{ percent: number }>({
    percent: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const onChange = async (e: any) => {
    const json = await excel2Json(e);
    const fields = json[0].map((item: string) => item.toLowerCase());

    let increment: number = 1000;
    const jsonContent = json.slice(1).map((item: any) => {
      const obj: any = {};
      fields.forEach((field: string, index: number) => {
        if (!['slug', 'id'].includes(field)) {
          obj[field] = (item[index] || '').trim();
        }
      });
      obj.category = obj.category?.toLowerCase();
      obj.content_id = `${String(Date.now())}${increment}`;
      obj.slug = `${obj.category?.toLowerCase()}-${obj.content_id}`;
      increment += 1;
      return obj;
    });
    setContentHub(jsonContent);
  };

  // const delay = (time: number) =>
  //   new Promise((resolve) => setTimeout(resolve, time));

  // const processRow = async (content: ContentHub) => {
  //   setProgress((prevCount) => ({
  //     value: prevCount.value + 1,
  //     percent: Math.round(((prevCount.value + 1) / contentHub.length) * 100),
  //   }));
  //   return postContent(content);
  // };

  // const [counter, setCounter] = useState<number>(0);

  const handleUpsertCategories = async (contentHub: ContentHub[] = []) => {
    const input: CategoriesInput = {
      categories: [],
      tags: [],
      genders: [],
    };
    contentHub.forEach((content: ContentHub) => {
      if (content.gender) {
        input.genders.push({
          name: content.gender,
          slug: stringToSlug(content.gender.trim()),
        });
      }
      if (content.category && content.tags) {
        input.categories.push({
          name: content.category?.trim().toLowerCase() || '',
          slug: stringToSlug(content.category.trim()),
        });
        content.tags?.split(',').forEach((tag) => {
          input.tags.push({
            categoryString: stringToSlug(content.category?.trim()),
            name: tag.trim(),
            slug: stringToSlug(tag.trim()),
          });
        });
      }
    });
    input.genders = getUniqueListBy(input.genders, ['slug']);
    input.categories = getUniqueListBy(input.categories, ['slug']);
    input.tags = getUniqueListBy(input.tags, ['slug', 'categoryString']);
    await upsertAllCategories(input);
  };

  const pushToDBEvent = async (): Promise<void> => {
    try {
      setLoading(true);
      setInterval(() => {
        setProgress((prevCount) => ({
          percent: prevCount.percent + 1 === 99 ? 99 : prevCount.percent + 1,
        }));
      }, 200);
      const result = await postContent(JSON.stringify(contentHub));
      if (allowCreateCateTag) {
        await handleUpsertCategories(contentHub);
      }
      notification.success({message: 'Create excel file successful', duration: 3, placement: 'topLeft'});
      setLoading(false);
      if (result.status === 200) {
        setTimeout(() => {
          navigate('/admin/edit-database');
        }, 500);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const allowCreateCategories = (e: CheckboxChangeEvent) => {
    allowCreateCateTag = e.target.checked;
  };

  return (
    <div>
      <TitleStyle>Upload Excel</TitleStyle>
      <input type="file" onChange={onChange} style={{paddingTop: 8, paddingBottom: 8}} />
      {progress.percent && (
        <ProgressStyle>
          <Progress percent={progress.percent} status="active" />
        </ProgressStyle>
      )}
      {/* <p>
        Process: {progress.value} / {contentHub.length} totals
      </p> */}
      {/* <p>Timer: {counter} seconds</p> */}
      <br />

      <div>
        <Checkbox onChange={allowCreateCategories}>Allow Create Categories/Tags from Excel file</Checkbox>
      </div>
      <br />
      {loading && <Spin />}
      <EditTable contentHub={contentHub} pushToDBEvent={pushToDBEvent} />
    </div>
  );
};

export default FillIn;

const TitleStyle = styled.h1`
  font-size: 1.5em;
  color: palevioletred;
  
`;

const ProgressStyle = styled.div`
  width: 800px;
`;
