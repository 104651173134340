import React, {useEffect, useRef, useState} from 'react'
import {Button, Collapse, Form, Input, notification, Radio, Space, Spin} from 'antd'
import ApiService from '@/services/api.service'
import {TextAreaRef} from 'antd/es/input/TextArea'

type Props = {};

const DoSpaceToExcel = (props: Props) => {
	const [enableDownload, setEnableDownload] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [rawExcel, setRawExcel] = useState<ArrayBuffer>()
	const [descTemplateType, setDescTemplateType] = useState<string>('wpImageAndButton')
	const [descTemplate, setDescTemplate] = useState<string>('')
	const [form] = Form.useForm()

	const onFinish = async (formValue) => {
		delete formValue.descTemplateType
		formValue.template = descTemplate
		try {
			setEnableDownload(false)
			setLoading(true)
			const {data} = await ApiService.post('AppUsers/createDoSpaceExcel', formValue, {
				responseType: 'arraybuffer',
			})
			notification.success({message: 'Create excel file successful'})
			setRawExcel(data)
			setEnableDownload(true)
		} catch (err) {
			setEnableDownload(false)
			let errMsg = err.message
			try {
				const errResp = JSON.parse(new TextDecoder().decode(err.response.data))
				errMsg = errResp.error.message
			} catch (e) {
			}
			notification.error({
				message:     'Create excel file failed',
				description: errMsg,
			})
		} finally {
			setLoading(false)
		}
	}

	const downloadExcel = () => {
		if (!rawExcel) return
		const blob = new Blob([rawExcel], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		})
		const curTime = (new Date()).toISOString()
			.split('.')[0]
			.replace('T', ' ')
			.replace(/:/gm, '-')
		const objectUrl = URL.createObjectURL(blob)
		const fileLink = document.createElement('a')
		fileLink.href = objectUrl
		fileLink.download = `DO Space ${curTime}.xlsx`

		// triggers the click event
		fileLink.click()
		// window.open(objectUrl)
	}

	useEffect(() => {
		form.setFieldValue('descTemplateType', descTemplateType)
		if (descTemplateType === 'custom') return
		switch (descTemplateType) {
			case 'wpImageAndButton': {
				setDescTemplate(`<!-- wp:image {"align":"center", "id":8788,"sizeSlug": "full","linkDestination":"custom"} -->
<div class="wp-block-image">
\t<figure class="aligncenter size-full">
\t\t<a href="$descLink$" target="_blank" rel="noopener">
\t\t\t<img src="$imgLink$" alt="" class="wp-image-8788" style="width: 512px; height: 512px;"/>
\t\t</a>
\t</figure>
</div>
<!-- /wp:image -->
<!-- wp:buttons {"contentJustification":"center"} -->
\t<div class="wp-block-buttons is-content-justification-center">
\t<!-- wp:button -->
\t<div class="wp-block-button">
\t\t<a class="wp-block-button__link" href="$descLink$" target="_blank" rel="noreferrer noopener">Download</a>
\t</div>
\t<!-- /wp:button -->
</div>
<!-- /wp:buttons -->`)
				break
			}
			case 'videoTag': {
				setDescTemplate(`<p><video poster="$imgLink$" controls="controls" width="100%" height="auto">&nbsp; <source src="$descLink$"></video></p>`)
				break
			}
		}
	}, [descTemplateType])

	return (<div style={{
		display:        'flex',
		flexDirection:  'column',
		justifyContent: 'center',
		width:          '100%',
	}}>
		<Form
			disabled={loading}
			form={form}
			name="doSpaceForm"
			labelCol={{span: 6}}
			wrapperCol={{span: 18}}
			style={{maxWidth: 1100, paddingRight: 32, width: '100%'}}
			initialValues={{
				'originEndpoint': 'https://kontent.ams3.digitaloceanspaces.com',
				'descTemplate':   'wpImageAndButton',
				// 	'titleUrl':       'https://cloud.digitalocean.com/spaces/kontent?path=video%2Fwomen%2FALL_LANG%2Ffitness%2FEN%2F',
				// 	'imageUrl':       'https://cloud.digitalocean.com/spaces/kontent?path=video%2Fwomen%2FALL_LANG%2Ffitness%2Fimag%2F',
				// 	'descriptionUrl': 'https://cloud.digitalocean.com/spaces/kontent?path=video%2Fwomen%2FALL_LANG%2Ffitness%2FEN%2F'
			}}
			onFinish={onFinish}
			// onFinishFailed={onFinishFailed}
			autoComplete="off"
		>

			<Form.Item
				label={'Origin Endpoint'}
				name={'originEndpoint'}
				rules={[{required: true, message: 'Please input DO Space origin endpoint'}]}
			>
				<Input/>
			</Form.Item>

			<Form.Item
				label={'Title'}
				name={'titleUrl'}
				rules={[
					{required: true, message: 'Please input title url'},
					{type: 'url', message: 'This field must be a valid url.'},
				]}
			>
				<Input/>
			</Form.Item>

			<Form.Item
				label={'Image'}
				name={'imageUrl'}
				rules={[
					{required: true, message: 'Please input image url'},
					{type: 'url', message: 'This field must be a valid url.'},
				]}
			>
				<Input/>
			</Form.Item>

			<Form.Item
				label={'Description EN links'}
				name={'descriptionUrl'}
				rules={[
					{required: true, message: 'Please input description url'},
					{type: 'url', message: 'This field must be a valid url.'},
				]}
			>
				<Input/>
			</Form.Item>

			<Form.Item
				name={'descTemplateType'}
				label={'Description Template'}
				rules={[
					{required: true, message: 'Please select description template'},
				]}
			>
				<Radio.Group
					onChange={e => {
						const newDescTemplateType = e.target.value
						if (newDescTemplateType === 'custom') {
							setDescTemplate('')
						}
						setDescTemplateType(newDescTemplateType)
						form.setFieldValue('descTemplateType', newDescTemplateType)
					}}
					value={descTemplateType}
				>
					<Radio value="wpImageAndButton"> WP Image & Button </Radio>
					<Radio value="videoTag"> Video tag </Radio>
					<Radio value="custom"> Custom </Radio>
				</Radio.Group>
				<span style={{
					display:    'block',
					wordWrap:   'break-word',
					whiteSpace: 'normal',
					marginTop:  8,
				}}>Placeholder: $imgLink$ $descLink$</span>
				<Input.TextArea value={descTemplate} onChange={(e) => {
					setDescTemplateType('custom')
					setDescTemplate(e.target.value)
				}}/>
			</Form.Item>

			<Collapse style={{marginBottom: 16}} ghost>
				<Collapse.Panel header={'Space Credential'} key={'doCredential'}>
					<Form.Item label={'Space Access Key'} name={'accessKey'}>
						<Input/>
					</Form.Item>

					<Form.Item label={'Space Secret Key'} name={'secretKey'}>
						<Input/>
					</Form.Item>
				</Collapse.Panel>
			</Collapse>

			<Form.Item wrapperCol={{offset: 8, span: 16}}>
				<Space>
					<Button type={'primary'} htmlType={'submit'} loading={loading}>
						Create Excel file
					</Button>
					<Button type={'primary'} disabled={!enableDownload} onClick={downloadExcel}>
						Download Excel file
					</Button>
				</Space>
			</Form.Item>
		</Form>
	</div>)
}

export default DoSpaceToExcel
