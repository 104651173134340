import { Filter } from './../../../types';
import axios from 'axios';
import { Portal } from '../types';

export const getList = async (filter: Filter = {}) => {
    try {
        const { data } = await axios.get('/Portals', {
            params: {
                filter,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getCount = async () => {
    try {
        const { data } = await axios.get('/Portals/count', {
            params: {
                where: {},
            },
        });
        return data?.count || 0;
    } catch (error) {
        throw new Error(error);
    }
};

export const deletePortals = async (ids: number[]) => {
    try {
        await Promise.all(ids.map((id: number) => axios.delete(`/Portals/${id}`)));
    } catch (error) {
        throw new Error(error);
    }
};

export const createPortal = async (portal: Portal) => {
    try {
        portal.name = portal.name?.trim();
        portal.url = portal.url.trim();
        const { data } = await axios.post('/Portals', portal);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const patchPortal = async (portal: Portal) => {
    try {
        portal.name = portal.name?.trim();
        portal.url = portal.url.trim();
        const { data } = await axios.patch(`/Portals/${portal.id}`, portal);
        return data;
    } catch (error) {
        throw new Error(error);
    }
}