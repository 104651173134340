import { ContentHub } from '@/types';
import { Form, Table, Image, Typography, Space, Button, message, Input, Popconfirm, Tag } from 'antd';
import React, { useRef, useState } from 'react';
import { fallbackImage } from '@/pages/ViewAndPush/components/fallback-image'
import { ColumnType, TableProps } from 'antd/lib/table';
import type { InputRef } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import Highlighter from 'react-highlight-words';
import { Company as CompanyType } from '@/pages/Company/types';
import { Portal } from '@/pages/Portal/types';

// const [searchText, setSearchText] = useState('');
// const [searchedColumn, setSearchedColumn] = useState('');
type Prop = {
  companyList?: CompanyType[];
  portalList?: Portal[];
  disableSearch?: boolean;
  type?: string;
  page: number;
  total: number;
  setTotal?: Function;
  pageSize: number;
  data: ContentHub[];
  setPagination: Function;
  selectedRowKeys?: React.Key[];
  setSelectedRowKeys?: Function;
  addToCartEvt?: Function;
  searchText?: string;
  setSearchText?: Function;
  searchedColumn?: string;
  setSearchedColumn?: Function;
  loadingTable?: boolean;
  viewDetailEvent: (id: number) => void;
  removeFromCartEvt?: (id: number) => void;
  deleteRowEvent?: (id: number) => void;
};

type DataIndex = keyof ContentHub;


const renderDescription = function (text, record, index) {
  return <Typography.Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
    {text}
  </Typography.Paragraph>
}

const ContentTable = (props: Prop) => {
  const [form] = Form.useForm();

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (props.setSelectedRowKeys) {
      props.setSelectedRowKeys(newSelectedRowKeys);
    }
  };

  const rowSelection = {
    selectedRowKeys: props.selectedRowKeys || [],
    onChange: onSelectChange,
  };
  const searchInput = useRef<InputRef>(null);
  // Filter Search

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    props.setSearchText ? props.setSearchText(selectedKeys[0]) : '';
    props.setSearchedColumn && props.setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    props.setSearchText ? props.setSearchText('') : '';
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<ContentHub> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => props.disableSearch ? '': (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              props.setSearchText ? props.setSearchText((selectedKeys as string[])[0]) : '';
              props.setSearchedColumn && props.setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => props.disableSearch ? '': (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      //Xử lý số trang sau khi search      
      props.setTotal && props.setTotal(0);
      if (record[dataIndex]?.toString().toLowerCase().includes((value as string).toLowerCase())) {
        props.setTotal && props.setTotal((pre: number) => pre++);
        return true;
      }
      return false;
    }
    ,
    onFilterDropdownVisibleChange: open => {
      if (open) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      props.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[props.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const addToCart = (record: ContentHub) => {
    if (props.addToCartEvt) {
      props.addToCartEvt(record.id);
    }
  }

  const [idRowDelete, setIdRowDelete] = useState<number>(0);

  const deleteRow = (record: ContentHub) => {
    setIdRowDelete(record.id);
  }

  const okConfirm = () => {
    props.deleteRowEvent && props.deleteRowEvent(idRowDelete);

  }
  const cancelConfirm = () => {
  }

  const getCompanysName = (record: any) => {
    const used = record?.used?.split(',') || [];
    const removeDup = [...new Set(used)];
    if (!props.companyList || !props.portalList) {
      return [];
    }
    const mySet = new Set();
    removeDup.forEach(portal => {
      const portalFind = props.portalList?.find(item => item.name === portal);
      if (!portalFind?.companyId) {
        return;
      }
      const companyFind = props.companyList?.find(item => item.id === portalFind?.companyId);
      if (!companyFind?.name || companyFind?.name === 'COMPANY NAME') {
        return;
      }
      if (!mySet.has(companyFind?.name)) {
        mySet.add(companyFind?.name);
      }
    }); 
    return [...mySet];
  }
  const listFaultyApp = [
   "10K Journey",
"Bodybuilding Xtra",
"Bodybuilding Club X",
"Calm Pro",
"Calo Burn for Starters",
"Cardio Easy",
"Cardio Hardcore",
"Cardio Medior",
"20 Best Workout Routines",
"Cooking Book",
"Active Fitness Tracker",
"Crossfit Pal",
"Crossfit Tracker",
"Daily Yoga",
"Detox Master",
"Easy Daily Meal Prep",
"25 Best Yoga Poses",
"Full Body Training",
"Female Fit 2021",
"Bellyfat Free",
"Fit Virtual Coach",
"Body Weight Tricks",
"Go Xfit 2021",
"Gym Songs Offline",
"BMI Train Hard",
"Gymguru",
"Healthy Cheat Meals",
"Healthy Recipes 2021",
"Healthy Recipes for Vegans",
"Healthy Weight Loss for Women",
"Healthy Weight Loss for Men",
"Heartrate Pal",
"Gym Facts",
"Gym Notes",
"HIIT Beginner",
"HIIT Routine",
"Home Workout Pro",
"iWorkout",
"Let's Lose Fat",
"Medisoul",
"Keep Training Pro",
"Motivational Music Pro",
"Muscle Training 2.0",
"Muzic4fit",
"Only Hardwork",
"Outdoor Fit",
"Premium Training Music",
"Morning Playlist 2021",
"Meditation Plus",
"Play Fit",
"Soundwave",
"Pro Training Assistant",
"Tabata Timer",
"Peaceful Meditation",
"Workout Music Vol.12",
"Workout How",
"Workout Planner 2021",
"Workout Routine Pro",
"Workout Routine Easy",
"Weighloss Training",
"Women Workouts at Home",
"Workout Motiv",
"Workout Routine Medior",
"Yoga Music Deluxe",
"Yoga Master Practice",
"Yoga Panda",
"Zen Mind",
"Yoga Best Collection 2021",
"BMI Calculator",
"Meditatime",
"Soup of the Day"
  ];
const lowerCaseList = listFaultyApp.map(app => app.toLowerCase());
  const columns = [
    // { title: 'ID', dataIndex: 'content_id', editable: true, width: '90px' },
    // {
    //   title: 'SLUG',
    //   dataIndex: 'slug',
    //   editable: true,
    //   width: '180px',
    // },
    {
      title: 'TITLE_EN',
      dataIndex: 'title_en',
      editable: true,
      key: 'title_en',
      width: '280px',
      ...getColumnSearchProps('title_en'),
      render: (text,record,index) => {
        if(lowerCaseList.includes(text.trim().toLowerCase())){
          return  <><Tag color="red">FAULTY</Tag> {text}</>;
        }else{
          return text;
        }
      }
    },
    {
      title: 'IMAGE',
      dataIndex: 'image',
      editable: false,
      render: (text, record, index) => {
        return <Image width={100} height={100} src={text} fallback={fallbackImage} />
      },
      width: '180px',
    },
    {
      title: 'CATEGORY',
      dataIndex: 'category',
      editable: true,
      width: '120px',
      sorter: (a, b) => a.category.localeCompare(b.category),

    },
    {
      title: 'GENDER', dataIndex: 'gender', editable: true,
      width: '120px',
      sorter: (a, b) => a.gender.localeCompare(b.gender),

    },
    {
      title: 'TAGS',
      dataIndex: 'tags',
      editable: true,
      sorter: (a, b) => a.tags.localeCompare(b.tags),
    },
    {
      title: 'USED', dataIndex: 'used_count', editable: true,
      sorter: (a, b) => a.used_count - b.used_count,
      render: (_, record: any) => <>
          <p>{record.used_count}</p>
          {/* {
            getCompanysName(record)?.map((name, index) => <p key={index} style={{fontSize: 10}}>{name}</p>)
          } */}
      </>
    },
    {
      title: 'CREATED', dataIndex: 'content_id',
      sorter: (a, b) => a.content_id - b.content_id,
      render: (_, record: any) => <>
        {
          record.content_id && <p> {
              new Date(parseInt(String(record?.content_id).slice(0, 13), 10)).toLocaleString()
            } </p>
        }
      </>
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (record) => (
        <Space size="middle">
          {
            props.type === 'view_and_push'
            && <Button type='primary' onClick={() => addToCart(record)}>Add to Cart</Button>
          }
          {
            props.type === 'cart_modal'
            && <Button type='primary' onClick={() => props.removeFromCartEvt && props.removeFromCartEvt(record.id)}>Remove from Cart</Button>
          }
          <Button type='primary' onClick={() => props.viewDetailEvent(record.id)}>
            {props.type === 'edit_database' ? 'Edit' : 'View Detail'}
          </Button>
          {
            props.type === 'edit_database' &&
            (
              <Popconfirm
                title="Are you sure to delete this row?"
                onConfirm={okConfirm}
                onCancel={cancelConfirm}
                okText="Yes"
                cancelText="No"
              >
                <Button danger onClick={() => deleteRow(record)}>Delete</Button>
              </Popconfirm>
            )
          }
        </Space >
      ),
    },
    // { title: 'DESCRIPTION_EN', dataIndex: 'description_en', render: renderDescription },
    // { title: 'TITLE_ES', dataIndex: 'title_es', editable: true },
    // { title: 'DESCRIPTION_ES', dataIndex: 'description_es', render: renderDescription },
    // { title: 'TITLE_IT', dataIndex: 'title_it', editable: true },
    // { title: 'DESCRIPTION_IT', dataIndex: 'description_it', render: renderDescription },
    // { title: 'TITLE_FR', dataIndex: 'title_fr', editable: true },
    // { title: 'DESCRIPTION_FR', dataIndex: 'description_fr', render: renderDescription },
    // { title: 'TITLE_CH', dataIndex: 'title_ch', editable: true },
    // { title: 'DESCRIPTION_CH', dataIndex: 'description_ch', render: renderDescription },
    // { title: 'TITLE_PT', dataIndex: 'title_pt', editable: true },
    // { title: 'DESCRIPTION_PT', dataIndex: 'description_pt', render: renderDescription },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return col;
  });

  const onChange = (pagination, filters, sorter, extra) => {
    if (sorter.field) {
      const order = sorter.order === 'descend' ? 'DESC' : 'ASC';
      props.setPagination({ page: pagination.current, pageSize: pagination.pageSize, order: `${sorter.field} ${order}` });
    }
  };

  return (
    <Form form={form} component={false}>
      <Table
        loading={props.loadingTable}
        rowKey="id"
        bordered
        dataSource={props.data}
        columns={mergedColumns}
        rowClassName="editable-row"
        rowSelection={rowSelection}
        pagination={{
          position: ['bottomLeft', 'topLeft'],
          current: props.page,
          pageSize: props.pageSize,
          total: props.total,
          onChange: (page, pageSize) => {
            props.setPagination({ page, pageSize });
          },
          // defaultPageSize: 10,
          // showSizeChanger: true, 
          // pageSizeOptions:['5','10','20','30','50','100','200'],
        }}
        onChange={onChange}
      // scroll={{ y: 840, x: 4000 }}
      />

    </Form>
  );
};

export default ContentTable;
