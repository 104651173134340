import React, { useEffect } from "react";
import { Button, Modal, Input, Form, } from 'antd';

type Prop = {
    accountInfo: Record<string, any>;
    isModalOpen: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    onFinishFailed: (errorInfo: any) => void;
    onFinish: (values: Record<string, any>) => void;
}

const AccountModal = (props: Prop) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (props.isModalOpen && !props.accountInfo?.id) {
            form.resetFields();
        }
    }, [props.isModalOpen])

    useEffect(() => {
        form.setFieldsValue(props.accountInfo);
    }, [form, props.accountInfo])

    const clearForm = () => {
        form.resetFields();
    }

    const onFinish = (data: Record<string, any>) => {
        props.onFinish(data);
    }

    return (
        <Modal
            forceRender
            footer={[
                <Button key="back" onClick={props.handleCancel}>
                    Cancel
                </Button>,
            ]}
            title={props.accountInfo?.id ? 'Update Account' : 'Create Account'}
            open={props.isModalOpen}
            onOk={props.handleOk}
            onCancel={props.handleCancel}
        >
            <button onClick={clearForm}>clear</button>
            <Form
                name="basic"
                form={form}
                autoComplete="off"
                onFinish={onFinish}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={props.accountInfo}
                onFinishFailed={props.onFinishFailed}
            >
                <Form.Item
                    style={{ display: 'none' }}
                    label="id"
                    name="id"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Name"
                    name="realm"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        },
                        {
                            required: true,
                            message: 'Please input your E-mail!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AccountModal;