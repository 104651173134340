import { ContentHub } from '@/types';
import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { syncToWP } from '../ViewAndPush/services/wordpress.services';
import { DataSync } from '../ViewAndPush/types';
import EditTable from './components/edit-table';
import {
  deleteContentHubs,
  getCount,
  getList,
  updateContentHub,
} from './services/edit-database';
import { Company as CompanyType } from '../Company/types';
import { Portal } from '@/pages/Portal/types';
import { getList as getListCompany } from '../Company/services/company.services';
import { getList as getListPortal } from '../Portal/services/portal.services';

type Prop = {};

const EditDatabase = (_: Prop) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [total, setTotal] = useState<number>(0);
  const [dataList, setDataList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [orderFilter, setOrderFilter] = useState<string>('id DESC');
  const [loadingTable, setLoadingTable] = useState<boolean>(false); 

  const [companyList, setCompanyList] = useState<CompanyType[]>([]);
  const [portalList, setPortalList] = useState<Portal[]>([]);

  const fetchTotal = async () => {
    const filter: Record<string, any> = { order: orderFilter, where: {title_en: {regexp: searchValue} }};
    let result = await getCount(filter);
    setTotal(result);
  }
  const fetchDataList = async () => {
    setLoadingTable(true);
    let result = await getList({skip: (page - 1) * pageSize, limit: pageSize, order: orderFilter, where: {title_en: {regexp: searchValue} }});
    setDataList(result);
    setLoadingTable(false);
  }
  useEffect(()=>{
    Promise.all([fetchTotal(), fetchDataList()]);
  },[page, pageSize, searchValue, orderFilter]);

  const setPaginationEvt = (data: { page: number; pageSize: number, order: string }) => {
    setPage(data.page);
    setPageSize(data.pageSize);
    if (data.order) {
      setOrderFilter(data.order);
    }
  };

  const syncToPortal = async (data: DataSync): Promise<void> => {
    const myArray = data?.post?.used?.split(",");
    let uniqueArray = [...new Set(myArray)]; // remove duplicate element
    uniqueArray = uniqueArray.filter(e => e); // remove empty element
    uniqueArray = uniqueArray.map(e => { // remove '/' character
      const last = e.charAt(e.length - 1);
      if (last === '/')
        return e.slice(0, -1)
      return e
    });
    let selectedPortal = uniqueArray;
    Promise.all(
      selectedPortal.map((portal: string) => syncToWP(data, portal + '/wp-json/ccpplugin/v1/update_post'))
    );
  };
  // const deletePostsOnPortal = async (data: DataSync): Promise<void> => {
  //   const myArray = data?.post?.used?.split(",");
  //   let uniqueArray = [...new Set(myArray)]; // remove duplicate element
  //   uniqueArray = uniqueArray.filter(e => e); // remove empty element
  //   uniqueArray = uniqueArray.map(e => { // remove '/' character
  //     const last = e.charAt(e.length - 1);
  //     if (last === '/')
  //       return e.slice(0, -1)
  //     return e
  //   });
  //   let selectedPortal = uniqueArray;
  //   Promise.all(
  //     selectedPortal.map((portal: string) => syncToWP(data, portal + '/wp-json/ccpplugin/v1/delete_post'))
  //   );
  // };
  const listPortals = [
"hiitjump.com",
"sporty-workout.com",
"bodyweightguide.com",
"cardioforever.com",
"readytobox.com",
"ready2box.com",
"endorfinemachine.com",
"runwaytofit.com",
"themaxplay.com",
"games-forest.com",
"traintotal.com",
"contact-verismati.com",
"getmeshaped.com",
"code4fitness.com",
"active-healthy.com",
"thebodygoal.com",
"simplefitclub.com",
"theboostedbody.com",
"aimforgames.com",
"play-pirate.com",
"workout-master.com",
"fit-ranger.com",
"lean-machine.com",
"uryogapath.com",
"greenjourneydiet.com",
"gaming-sensei.com",
"arcadewaves.com",
"game-soldier.com",
"muscle-team.com",
"submitforfit.com",
"seatedharmony.com",
"leafgreendiet.com",
"inner-waves.com",
"gymtoslim.com",
"curvyfacts.com",
"coach4body.com",
"thrive-master.com",
"getyoustronger.com",
"athleticguides.com",
"energy-empire.com",
"musclesnation.com",
"vitaldistrict.com",
"globe4health.com",
"the-athletic-guides.com",
"the-energy-empire.com",
"the-muscles-nation.com",
"the-vital-district.com",
"globe-for-health.com",
"healthy-drill.com",
"mybestworkout.com",
"themusclepump.com",
"sport-skills.com",
"sportive-life.com",
"toningmoves.com",
"pure-shaped.com",
"musclesburn.com",
"shapedgym.com",
"detoxbodies.com",
"games-2play.com",
"the-gamecity.com",
"power-gamer.com",
"healthytargets.com",
"strictlystronger.com",
"rapidstrengths.com",
"habits4shape.com",
"thefitshaper.com",
"the-healthy-targets.com",
"habits-for-shape.coms",
"the-strictly-stronger.com",
"the-rapid-strengths.com",
"the-fit-shaper.com",
"strengthplayers.com",
"physicalride.com",
"sweatyshape.com",
"nourishedbodies.com",
"strongerleague.com",
"the-strength-players.com",
"the-physical-ride.com",
"the-sweaty-shape.com",
"the-nourished-bodies.com",
"the-stronger-league.com",
"health-spirits.com",
"vital-movements.com",
"rules2fit.com",
"stepupmoves.com",
"theshapingpurpose.com",
"the-health-spirits.com",
"the-vital-movements.com",
"rules-to-fit.com",
"the-stepup-moves.com",
"the-shaping-purpose.com",
"toneuptime.com",
"exercise2health.com",
"purelyprogress.com",
"roomforchamps.com",
"obtainthegain.com",
"tone-up-time.com",
"exercise-2-health.com",
"the-purely-progress.com",
"room-for-champs.com",
"obtain-the-gain.com",
"healthy-hormones.com",
"energeticspirits.com",
"direction4action.com",
"graspyourgoal.com",
"liftyourweights.com",
"the-healthy-hormones.com",
"the-energetic-spirits.com",
"direction-4-action.com",
"grasp-your-goal.com",
"lift-your-weights.com",
"bloomingstrength.com",
"funtostayfit.com",
"jump2strength.com",
"major-muscles.com",
"yourinnerpowers.com",
"the-blooming-strength.com",
"fun-tostay-fit.com",
"jump-2-strength.com",
"the-major-muscles.com",
"your-inner-powers.com",
"clean-diets.com",
"shaping-body.com",
"lovemefitter.com",
"transform2fit.com",
"health-ranger.com",
"the-clean-diets.com",
"the-shaping-body.com",
"love-me-fitter.com",
"transform-2-fit.com",
"the-health-ranger.com",
"fittestlives.com",
"stable-health.com",
"healthyflows.com",
"workoutground.com",
"fitness-leaders.com",
"the-fittest-lives.com",
"the-stable-health.com",
"the-healthy-flows.com",
"the-workout-ground.com",
"the-fitness-leaders.com",
"gainingcoach.com",
"sportiveguide.com",
"toneupfit.com",
"fitterstart.com",
"athleticround.com",
"sourcetorun.com",
"musclesmood.com",
"tonictrain.com",
"inshapebeauty.com",
"healthierlines.com",
"daily-body.com",
"physical-yoga.com",
"fit-workout.com",
"sweatycardio.com",
"gym-train.com",
"fresh-diets.com",
"fitflow.ai",
"home-cardio.com",
"yoga-plan.com",
"weight-train.com",
"strength-boost.com",
"clubaerobic.com",
"cluba-erobic.com",
"thecluba-erobic.com",
"the-cluba-erobic.com",
"diet4beginners.com",
"active-boost.com",
"detox-boost.com",
"workout-tip.com",
"lean-muscles.com",
"tryweights.com",
"try-weights.com",
"thetry-weights.com",
"the-try-weights.com",
"changeyourbite.com",
"flex-workout.com",
"top-detox.com",
"gym-armour.com",
"exercise-boost.com",
"tonedglutes.com",
"toned-glutes.com",
"thetoned-glutes.com",
"the-toned-glutes.com",
"strengthchief.com",
"getyourpump.com",
"activemeditate.com",
"yoga2destress.com",
"sweatyhiit.com",
"the-strength-chief.com",
"get-your-pump.com",
"the-active-meditate.com",
"yoga-to-destress.com",
"the-sweaty-hiit.com",
"stamineboost.com",
"strengthlearn.com",
"yourbodybuilders.com",
"yogaprofs.com",
"squatgirly.com",
"the-stamine-boost.com",
"the-strength-learn.com",
"your-body-builders.com",
"the-yoga-profs.com",
"the-squat-girly.com",
"fitcircuits.com",
"muscledmenrule.com",
"trainandgym.com",
"flowandhiit.com",
"womenyogamantra.com",
"the-fit-circuits.com",
"muscled-men-rule.com",
"train-and-gym.com",
"flow-and-hiit.com",
"women-yoga-mantra.com",
"cardiofever.com",
"weightsfit.com",
"pushuproutine.com",
"sportywomenrule.com",
"aerobic-fitness.com",
"the-cardio-fever.com",
"the-weights-fit.com",
"the-pushup-routine.com",
"sporty-women-rule.com",
"the-aerobic-fitness.com",
"squatfever.com",
"livingforfit.com",
"gymandtrain.com",
"flowandfitness.com",
"formtofit.com",
"the-squat-fever.com",
"living-for-fit.com",
"gym-and-train.com",
"flow-and-fitness.com",
"form-to-fit.com",
"urbestworkout.com",
"health-her.com",
"man-mademuscle.com",
"morningjogging.com",
"shapeandstart.com",
"bulkupmuscles.com",
"hiithype.com",
"girlsandweights.com",
"body-bump.com",
"feelinginshape.com",
"fitnessfeelings.com",
"fitflavorfusion.com",
"tonebodies.com",
"tone-bodies.com",
"the-tone-bodies.com",
"strengthbodies.com",
"strength-bodies.com",
"the-strength-bodies.com",
"the-strong-bodies.com",
"powered-fitness.com",
"energeticgoals.com",
"energetic-goals.com",
"the-energetic-goals.com",
"sportygoals.com",
"sporty-goals.com",
"the-sporty-goals.com",
"active-goals.com",
"muscles-fit.com",
"boxingathlete.com",
"boxing-athlete.com",
"the-boxing-athlete.com",
"athleticboxing.com",
"athletic-boxing.com",
"powerfulboxing.com",
"powerful-boxing.com",
"easilyyoga.com",
"fitness-sprint.com",
"crossfitboost.com",
"effective-exercise.com",
"crossfit-boost.com",
"theeffective-exercise.com",
"the-crossfit-boost.com",
"the-effective-exercise.com",
"crossfitforyou.com",
"natural-exercise",
"crossfit-foryou.com",
"thenatural-exercise",
"crossfit-hour.com",
"trendyrun.com",
"pullexercises.com",
"the-natural-exercise",
"feelcomfortably.com",
"cardiohour.com",
"cardio-hour.com",
"the-cardio-hour.com",
"weeklycardio.com",
"weekly-cardio.com",
"the-weekly-cardio.com",
"daily-cardio.com",
"systemworkout.com",
"activehiit.com",
"active-hiit.com",
"the-active-hiit.com",
"cardio-hiit.com",
"the-cardio-hiit.com",
"workout-hiit.com",
"flexandsquat.com",
"the-workout-hiit.com",
"boxersgym.com",
"pushthatmuscle.com",
"push-thatmuscle.com",
"push-that-muscle.com",
"pushthedumbell.com",
"pushthatdumbell.com",
"flexingyoga.com",
"pushthatbarbell.com",
"pushthebarbell.com",
"ready2workout.com",
"fitsportslover.com",
"fit-sportslover.com",
"fit-sports-lover.com",
"fitsportsloving.com",
"fit-sportsloving.com",
"2day2fit.com",
"fittrainlover.com",
"fit-trainlover.com",
"squatlikeher.com",
"hiitrepetition.com",
"hiit-repetition.com",
"thehiitrepetition.com",
"gymrepetition.com",
"gym-repetition.com",
"destresshere.com",
"healthrepetition.com",
"health-repetition.com",
"muscular-edge.com",
"fitnesspro4life.com",
"fitness-pro4life.com",
"thefitnesspro4life.com",
"gympro4life.com",
"gym-pro4life.com",
"living4thegym.com",
"living4the-gym.com",
"bodywarmup.com",
"fitnessdinasty.com",
"fitness-hour.com",
"thefitness-hour.com",
"the-fitnesshour.com",
"hourly-fitness.com",
"the-hourly-fitness.com",
"health-hour.com",
"getreadyandrun.com",
"the-health-hour.com",
"yokethemind.com",
"cardiohighlight.com",
"youcanbefittoo.com",
"you-canbefittoo.com",
"youcanbefit-too.com",
"wanttobefittoo.com",
"want-tobefittoo.com",
"betterfittoo.com",
"better-fittoo.com",
"glam-fitness.com",
"beingfitonline.com",
"being-fitonline.com",
"beingfit-online.com",
"be-fitonline.com",
"be-fit-online.com",
"feelfitonline.com",
"feel-fitonline.com",
"gymexerciser.com",
"bestyougain.com",
"gymwalkthrough.com",
"gym-walkthrough.com",
"gym-walk-through.com",
"fitnesswalkthroughs.com",
"cardio2enjoy.com",
"fitness-walkthroughs.com",
"fitwalkthrough.com",
"fit-walkthrough.com",
"sprintandpause.com",
"manyfitnessreps.com",
"many-fitnessreps.com",
"many-fitness-reps.com",
"lotfitnessreps.com",
"lot-fitnessreps.com",
"allfitnessreps.com",
"gymofstrength.com",
"all-fitnessreps.com",
"athletehabit.com",
"bodiesflow.com",
"bodies-flow.com",
"thebodiesflow.com",
"hitthatglove.com",
"thecardioflow.com",
"thecardio-flow.com",
"thehealth-flow.com",
"the-healthflow.com",
"runninglad.com",
"menlovefitness.com",
"loveandgetfit.com",
"love2getfit.com",
"hitthatbag.com",
"lovetogetfit.com",
"men-love-fitness.com",
"men-lovefitness.com",
"love-andgetfit.com",
"plaincardiojane.com",
"fastcardiogirl.com",
"fast-cardiogirl.com",
"fast-cardio-girl.com",
"fast-cardio.com",
"thefast-cardio.com",
"fastestcardio.com",
"fastest-cardio.com",
"musclescenter.com",
"secretworkouts.com",
"yogaforfemme.com",
"mind-mantra.com",
"the-mind-mantra.com",
"fitwith-yoga.com",
"fit-with-yoga.com",
"mindforyoga.com",
"mind-foryoga.com",
"mind-for-yoga.com",
"absandpower.com",
"keepfitfanatic.com",
"yourbigmuscles.com",
"timeforrun.com",
"me-slim.com",
"timefor-run.com",
"squatdeeply.com",
"grow-sho.com",
"exercises-gym.com",
"gymactively.com",
"yoga-heals.com",
"muscle-workout.com",
"relax-mantra.com",
"daily-hiit.com",
"movemastermind.com",
"fitwithcardio.com",
"loveurworkout.com",
"boxbrawler.com",
"ourchairyoga.com",
"hiittohealth.com",
"muscle-endurance.com",
"relaxingstretches.com",
"cardiogains.com",
"bicepbody.com",
"yogasynchronize.com",
"workoutwide.com",
"wallpilateszone.com",
"fieryboxing.com",
"urbiggestathlete.com",
"fit-fanatic.com",
"quick-cardio.com",
"relax-together.com",
"realforfitness.com",
"unleashedboxing.com",
"sprinttofit.com",
"onefitnow.com",
"actively-healthy.com",
"fitandboxing.com",
"shreddedworkouts.com",
"femmehiit.com",
"urhealtybody.com",
"mind2yoga.com",
"she-squats.com",
"boxer-gym.com",
"trendy-gym.com",
"yoga-balanced.com",
"forever-cardio.com",
"nextcardiolevel.com",
"mindfulcalmness.com",
"movegetfit.com",
"menpumpit.com",
"yoga-spotlight.com",
"bikeandcardio.com",
"class-fitness.com",
"fitfactorforge.com",
"explosive-gym.com",
"cloudyyoga.com",
"fitness-maximum.com",
"fit-changes.com",
"energizecardio.com",
"energizehere.com",
"gymforfit.com",
"gym-spotlight.com",
"dothatyoga.com",
"fit2boxing.com",
"pushroutines.com",
"ourfitformula.com",
"gainperfectshape.com",
"sprint-cardio.com",
"sportandflex.com",
"comfort-yoga.com",
"complete-workout.com",
"wallpilatesflow.com",
"intense-cardio.com",
"anywhere-fit.com",
"moving-yoga.com",
"exerciseisking.com",
"fitcoordination.com",
"hiitandgo.com",
"explosive-exercises.com",
"yoga-craving.com",
"cardioforhome.com",
"limitless-weights.com",
"cardio-academy.com",
"workout-everyday.com",
"fit-movement.com",
"flexible-yoga.com",
"workoutindepth.com",
"together-healthy.com",
"shapedbycardio.com",
"everywhere-yoga.com",
"uryogajourney.com",
"getrippedhere.com",
"hiitblast.com",
"boxtobefit.com",
"pushyourcardio.com",
"sport-complex.com",
"bodymaximum.com",
"boxtofit.com",
"box-bootcamp.com",
"cardioformen.com",
"cardio-zone.com",
"style-yoga.com",
"fittestchallenge.com",
"classic-cardio.com",
"stronger-body.com",
"pushforgreatness.com",
"graceful-yoga.com",
"fastfitmove.com",
"gymming-now.com",
"cardio-machine.com",
"training4cardio.com",
"cardiostronger.com",
"dynamicdietary.com",
"daretobox.com",
"best-cardio.com",
"workout-daily.com",
"total-workout.com",
"stay-shape.com",
"effectivepump.com",
"fastcalorieburn.com",
"push-urself.com",
"youryogasoul.com",
"movefitcentral.com",
"fitversus.com",
"wallpilatespro.com",
"celestialyogaflow.com",
"runyourfastest.com",
"yoga4homes.com",
"gymsforall.com",
"intensebarre.com"
];
  const syncToPortalTest = async (data: DataSync): Promise<void> => {
    const myArray = listPortals;
    let uniqueArray = [...new Set(myArray)]; // remove duplicate element
    uniqueArray = uniqueArray.filter(e => e); // remove empty element
    uniqueArray = uniqueArray.map(e => { // remove '/' character
      const last = e.charAt(e.length - 1);
      if (last === '/')
        return e.slice(0, -1)
      return e
    });
    console.log('viet uniqueArray.length',uniqueArray.length);
    let selectedPortal = uniqueArray;
    Promise.all(
      selectedPortal.map((portal: string) => syncToWP(data, portal + '/wp-json/ccpplugin/v1/update_post'))
    );
  };

  const setDataEvt = async (evt: {
    data: ContentHub | number[] | any;
    action: string;
  }) => {
    if (!evt.action) {
      return;
    }
    if (evt.action === 'DELETE') {
      setLoadingTable(true);
      const deleteIds: number[] = evt.data;
      setDataList(dataList.filter((item: any) => !deleteIds.includes(item.id)));
      await deleteContentHubs(evt.data);
      setLoadingTable(false);
      return;
    }
    if (evt.action === 'BOLD_HEADER') {
      setLoadingTable(true);
      // console.log('viet 1107 evt.data',evt.data)
      // console.log('viet 1107 evt.action',evt.action)
      const boldHeaderIds: number[] = evt.data;
      const listBoldHdeader = dataList.filter(item => boldHeaderIds.includes(item.id));
      // console.log('viet 1107 listBoldHdeader',listBoldHdeader);
      const formatDescription = (description) => {
        if (!description) return description;
        
        // Thay thế các ký tự xuống dòng
        description = description.replace(/\r\n/g, '<br>');
        
        // In đậm các dòng có số thứ tự (dạng số và chữ số La Mã)
        description = description.replace(/(<br>)(\d+\.\s.*?)(<br>)/g, '$1<strong>$2</strong>$3')
        .replace(/(<br>)([IVXLCDM]+\.\s.*?)(<br>)/g, '$1<strong>$2</strong>$3');
        
        // Kiểm tra nếu đã có dòng in đậm số thứ tự thì không in đậm bullet nữa
        if (!/<strong>\d+\.\s.*?<\/strong>/.test(description)) {
        description = description.replace(/(<br>)(•\s.*?)(<br>)/g, '$1<strong>$2</strong>$3');
        }
        
        return description;
      }
      try {
        await Promise.all(listBoldHdeader.map(post => {
            post.description_en = formatDescription(post.description_en);
            post.description_ch = formatDescription(post.description_ch);
            post.description_es = formatDescription(post.description_es);
            post.description_fr = formatDescription(post.description_fr);
            post.description_it = formatDescription(post.description_it);
            post.description_pt = formatDescription(post.description_pt);
            updateContentHub(post);
        }));
      } catch (error) {
          throw new Error(error);
      }
      setLoadingTable(false);
      return;
    }
    const result = await updateContentHub(evt.data);
    const indexOfContent = dataList?.findIndex(content => content.id === evt.data.id);
    if (indexOfContent >= 0) {
      dataList[indexOfContent] = result;
    }
    if(evt.action === 'SYNC') {
      await syncToPortal({post : evt.data});
      // await deletePostsOnPortal({post : evt.data});
    }
  };

  const deleteRowEvent = async (id: number) => {
    setLoadingTable(true);
    setDataList(dataList.filter((item: any) => item.id !== id));
    await deleteContentHubs([id]);
    setLoadingTable(false);
  }
  const onSearch = async (value: string) => {
    setSearchValue(value);
    setPage(1);
  };

  useEffect(() => {
    getListPortal().then(res => { setPortalList(res) })
    getListCompany().then(res => setCompanyList(res));
  }, []);

  return (
    <>
      <Input.Search  
        onSearch={onSearch}
        placeholder='input search text' 
        style={{width: 200}}
      />
      <EditTable
        loadingTable={loadingTable}
        disableSearch={true}
        type='edit_database'
        page={page}
        total={total}
        data={dataList}
        pageSize={pageSize}
        setData={setDataEvt}
        setPagination={setPaginationEvt}
        deleteRowEvent={deleteRowEvent}
        companyList={companyList}
        portalList={portalList}
      />
    </>
  );
};

export default EditDatabase;
