import React, { useEffect, useState } from 'react';
import { Button, Col, message, Modal, Row, Select, Collapse } from 'antd';

import FilterModal from '@/layouts/components/filter-modal';
// import { getList } from '@/pages/Portal/services/portal.services';
import { Portal } from '@/pages/Portal/types';
import { ContentHub } from '@/types';
import ApiService from '@/services/api.service';
import { DataPush, DuplicateContentPortals } from '../types';
import { getUserInfo } from '@/services/jwt.service'
import ReuseDataPusher from './reuse-data-pushed';
import PortalsDropdownModal from './portals-dropdown-modal';
import { PushHistory } from '@/pages/PushHistory/push-history-service';
// import { duplicatePostWP } from '../services/wordpress.services';
// import { fallbackImage } from './fallback-image';
import ViewAndPushShowDuplicate from './ViewPushShowDuplicate';
import {
  getContents,
} from '../../../services/content-hub-services';
import { getContentCanPushAndExist } from '../helper';

type Prop = {
  isModalVisible: boolean;
  selectedContentHubs: ContentHub[];
  routeFrom: 'viewAndPushPage' | 'addCartPage';
  portalList: Portal[];
  modalVisibleEvent: (
    open: boolean,
    data?: { langs: string[]; portals: Portal[] }
  ) => void;
  dataPushEvent: (data: DataPush, selectedPortal: Portal[]) => void;
};

const { Option } = Select;
const { Panel } = Collapse;

const getUserId = () => {
  try {
    const userInfoStr = getUserInfo();
    if (!userInfoStr) return undefined
    return JSON.parse(userInfoStr).id
  } catch {
    return undefined
  }
}

const PushSettings = (props: Prop) => {
  const [isModalVisibleView, setIsModalVisible] = useState(false);
  const [langsSelected, setLangsSelected] = useState<string[]>(['en']);
  const [portalIdsSelected, setPortalIdsSelected] = useState<number[]>([]);
  const [contenHubsByCategories, setContenHubsByCategories] = useState<
    { category: string; count: number }[]
  >([]);

  const [selectedContentHubs, setSelectedContentHubs] = useState<ContentHub[]>([]);
  // tmp comment, keep old logic
  // const [duplicateInfo, setDuplicateInfo] = useState<any[]>([]);
  const [postsCanPush, setPostsCanPush] = useState<DuplicateContentPortals[]>([]);
  const [postsExists, setPostsExists] = useState<DuplicateContentPortals[]>([]);

  const [loadingsDuplicate, setLoadingsDuplicate] = useState<boolean>(false);
  // const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(props.selectedContentHubs.map(e => e.id));
  const [loadingPushing, setLoadingPushing] = useState<boolean>(false);

  useEffect(() => {
    setSelectedContentHubs(props.selectedContentHubs);
  }, [props.selectedContentHubs]);

  const handleOk = () => {
    console.log('viet langSelected',langsSelected);
    const selectedPortal = props.portalList.filter((portal) => portalIdsSelected.includes(portal.id));
    if(!selectedPortal?.length){
      message.error('Please choose Portal');
      return ;
    }
    if(!langsSelected?.length){
      message.error('Please choose Languages 2');
      return ;
    }
    setIsModalVisible(true);
  };

  const handleCheckDuplicate = (portalIdsSelect: number[]) => {
    const selectedPortal = props.portalList.filter((portal) => portalIdsSelect.includes(portal.id));
    if(!selectedPortal?.length){
      setPostsCanPush([]);
      setPostsExists([]);
      // message.error('Please choose Portal');
      return ;
    }
    setLoadingsDuplicate(true);
    
    // tmp comment, keep old logic
    // let selectedPostIds = selectedContentHubs.map(e => e.content_id?e.content_id:'');
    // selectedPostIds = selectedPostIds.filter(e => e); //remove empty element;
    // let listPortalName = selectedPortal.map(e => e.name?e.name:'');
    // listPortalName = listPortalName.filter(e => e);//remove empty element;
    // listPortalName = listPortalName.map(e => { // remove '/' character
    //   const last = e.charAt(e.length - 1);
    //   if (last === '/')
    //     return e.slice(0, -1)
    //   return e
    // });
    // let duplicateData : any[] = [];
    // for( const portal of listPortalName){
    //   let temp = await duplicatePostWP(selectedPostIds, portal + '/wp-json/ccpplugin/v1/duplicate_post');
    //   if(temp.length){
    //     duplicateData.push({portal, data: temp});
    //   }
    // }
    // setDuplicateInfo(duplicateData);

    const {
      canPush,
      denyPush,
      contentsWithCanPushState,
    } = getContentCanPushAndExist(selectedContentHubs, selectedPortal);



    setPostsCanPush(canPush);
    setPostsExists(denyPush);
    setSelectedContentHubs(contentsWithCanPushState)

    setLoadingsDuplicate(false);
  };

  const handleCancel = () => {
    setPostsCanPush([]);
    setPostsExists([]);
    setPortalIdsSelected([]);
    
    props.modalVisibleEvent(false);
  };

  const handleOkView = async () => {
    if(!langsSelected?.length){
      message.error('Please choose Languages');
      return ;
    }
    const selectedPortal = props.portalList.filter((portal) => portalIdsSelected.includes(portal.id));
  
     
    if(!selectedPortal?.length){
      message.error('Please choose Portal');
      return ;
    }
    // loại bỏ khoảng trắng của portal.url
    for(const portal of selectedPortal){
      portal.name = portal.name?.trim();
      portal.url = portal.url.trim();
    }
    setLoadingPushing(true);
    const selectedPortalIdList = selectedPortal.map(portal => portal.id);

    const postsCanPush = selectedContentHubs.filter(post => post.canPush);
    const selectedPostList = postsCanPush.map(post => ({
      id: post.id,
      status: 'new',
    }));
    const userId = getUserId();
    // tạo push history record
    const { data: pushHistory } = await ApiService.post('PushHistories', {
      userId: userId,
      languages: langsSelected,
      posts: selectedPostList,
      portals: selectedPortalIdList,
      status: 'new'
    })

    props.dataPushEvent({
      configs: { langs: langsSelected, pushId: pushHistory.id },
      posts: postsCanPush,
    }, selectedPortal);

    setPostsCanPush([]);
    setPostsExists([]);
    setPortalIdsSelected([]);
    setLoadingPushing(false);
    setIsModalVisible(false);
  };

  const handleCancelView = () => {
    setIsModalVisible(false);
  };

  const handlePortalChange = (portalIds: number[]) => {
    setPortalIdsSelected(portalIds);
    handleCheckDuplicate(portalIds);
  };

  const onLangChange = (langs: string[]) => {
    setLangsSelected(langs);
  };

  const getContentHubsByCategory = () => {
    const result = selectedContentHubs.filter(content => content.canPush).reduce((acc, curr) => {
      const category = curr.category || '';
      const count = acc[category] ? acc[category] + 1 : 1;
      return { ...acc, [category]: count };
    }, {} as { [key: string]: number });

    const resultMap = Object.keys(result).map((key) => ({
      category: key,
      count: result[key],
    }));
    setContenHubsByCategories(resultMap);
  };

  const [showPortalsDropdown, setShowPortalsDropdown] = useState<boolean>(false);

  const handleOkPortalDropdown = () => {
    setShowPortalsDropdown(false);
    setIsModalVisible(true);
  }

  const showPortalsDropdownEvt = async (data: PushHistory) => {
    const ids = data.posts.map(item => item.id);
    const contents = await getContents({
      where: {
        id: {
          inq: ids
        }
      }
    });
    setSelectedContentHubs(contents);
    setPortalIdsSelected([]);
  }

  useEffect(() => {
    getContentHubsByCategory();
  }, [selectedContentHubs]);

  // tmp comment, keep old logic
  // CheckDuplicate
  // interface ExpandedDataType {
  //   key: React.Key;
  //   portal: string;
  //   total: number;
  //   language: string[];
  // }
  // const expandedRowRender = (data) => {
  //   console.log('viet expandedRowRender ',data);
  //   const columns: TableColumnsType<ExpandedDataType> = [
  //     {title : 'Portal', dataIndex: 'portal', key : 'portal'},
  //     {title : 'Post Total', dataIndex: 'total', key: 'total'},
  //     {title : 'Language', dataIndex: 'language', key: 'language'}
  //   ];
  //   return <Table columns={columns} dataSource={data} pagination={false} />;
  // }

 // tmp comment, keep old logic
  // const columns : TableColumnsType<ContentHub>  = [
  //   {
  //     title: 'TITLE_EN',
  //     dataIndex: 'title_en',
  //     key: 'title_en',
  //     width: '280px',
  //   },
  //   {
  //     title: 'IMAGE',
  //     dataIndex: 'image',
  //     render: (text, record, index) => {
  //       return <Image width={100} height={100} src={text} fallback={fallbackImage} />
  //     },
  //     width: '180px',
  //   },
  //   {
  //     title: 'CATEGORY',
  //     dataIndex: 'category',
  //     width: '120px',
  //   },
  //   {
  //     title: 'GENDER', dataIndex: 'gender', 
  //     width: '120px'
  //   },
  //   {
  //     title: 'TAGS', dataIndex: 'tags', 
  //     width: '180px'
  //   },
  //   {
  //     title: 'USED', dataIndex: 'used_count',
  //   }
  // ];

  // const rowSelection = {
  //   selectedRowKeys ,
  //   onChange: (selectedRowKeys: React.Key[], selectedRows: ContentHub[]) => {

  //    setSelectedRowKeys(selectedRowKeys);
  //     setSelectedContentHubs(selectedRows);
  //   },
  // };
  return (
    <>
      <Modal
        width={1200}
        title={"Push Settings (" + selectedContentHubs.length + " posts)" }
        open={props.isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="modal-custom"
        destroyOnClose={true}
        zIndex={1001}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            disabled={loadingsDuplicate}
          >
            OK for Push
          </Button>,
          // <Button
          //   key="link"
          //   type="primary"
          //   loading={loadingsDuplicate}
          //   onClick={handleCheckDuplicate}
          // >
          //   Check Duplicate
          // </Button>,
        ]}
      >
        <Row>
          <Col span={5}>
            <FilterModal onChange={onLangChange} />
          </Col>
          <Col span={19}>
            <h3 className="text-uppercase mb-3">portal</h3>
            
            <div>
              <p>Allow to push (<b>{postsCanPush.length} posts</b>). Existed in portals (<b>{postsExists.length} posts</b>)</p>
            </div>

            <Select
              showSearch
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              placeholder="Please select"
              mode="multiple"
              style={{ width: 700 }}
              value={portalIdsSelected}
              onChange={handlePortalChange}
              options={props.portalList.map(e => {
                return {
                  value: e.id,
                  label: e.name
                }
              })}
            />
        
            {
              props.routeFrom === 'viewAndPushPage' &&
              <ReuseDataPusher
                showPortalsDropdownEvt={showPortalsDropdownEvt}
              />
            }
            <br />
            
            {
              ( postsCanPush || postsExists ) && <ViewAndPushShowDuplicate
                postsCanPush={postsCanPush}
                postsExists={postsExists}
              />
            }
          
            {/* tmp comment, keep old logic */}
            {/* {duplicateInfo.length>0 && <Table
              rowKey="id"
              columns={columns}
              expandable={{ expandedRowRender : (record) => {
                  const columns: TableColumnsType<ExpandedDataType> = [
                    {title : 'Portal', dataIndex: 'portal', key : 'portal'},
                    {title : 'Instances', dataIndex: 'total', key: 'total', render: text => (text>1)?text + ' posts': text + ' post'},
                    {title : 'Language', dataIndex: 'language', key: 'language'}
                  ];
                  let data = duplicateInfo.map(e => {
                    let temp = e.data.filter(i => i.id === record.content_id);
                    if (!temp) {
                      return {};
                    }
                    return {portal: e?.portal, total: temp[0]?.total, language : temp[0]?.language?.toString()}
                  })
                  let some = data.map(e => e.total);
                  const sum = some.reduce((accumulator, value) => {
                    return accumulator + value;
                  },0);
                  if(sum)
                    return <Table rowKey="portal" columns={columns} dataSource={data} pagination={false} />;
                  else 
                    return "No duplicate instance.";
                }, defaultExpandAllRows : true}}
              rowSelection={{
                type: 'checkbox',
                hideSelectAll: true,
                ...rowSelection,
              }}

              dataSource = {props.selectedContentHubs}
              size="small"
            />} */}
          </Col>
        </Row>
      </Modal>
      <Modal
        zIndex={1002}
        className="modal-custom-chilren"
        open={isModalVisibleView}
        onOk={handleOkView}
        onCancel={handleCancelView}
        footer={[
          <Button
            key="link"
            className="text-uppercase"
            onClick={handleOkView}
            loading={loadingPushing}
            disabled={!Object.keys(contenHubsByCategories)?.length}
          >
            {' '}
            <b>i confirm</b>{' '}
          </Button>,
        ]}
      >
        <p className="text-center">
          You are about to push to{' '}
          <b>
            {props.portalList
              .filter((portal) => portalIdsSelected.includes(portal.id))
              .map((portal) => portal.name)
              .toString()}
          </b>{' '}
          the following data in <b>{langsSelected.toString()}</b>
        </p>
        { !Object.keys(contenHubsByCategories)?.length
          && <p style={{ textAlign: 'center', color: 'red'}}>
              <b>No posts for Push</b>
            </p>
        }
        {contenHubsByCategories.map((item, index: number) => (
          <p className="text-center" key={index}>
            {item.category}: <b>{item.count}</b>
          </p>
        ))}
      </Modal>

      <PortalsDropdownModal
        isModalOpen={showPortalsDropdown}
        portalList={props.portalList}
        handlePortalChange={handlePortalChange}
        handleOk={handleOkPortalDropdown}
        handleCancel={() => setShowPortalsDropdown(false)}
      />
    </>
  );
};

PushSettings.defaultProps = {
  selectedContentHubs: [],
  portalList: [],
};

export default PushSettings;
