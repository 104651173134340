import { Filter } from "@/types";
import axios from "axios";
import { Company } from "../types";

export const getList = async (filter: Filter = {}) => {
    try {
        const { data } = await axios.get('/Companies', {
            params: {
                filter,
            }
        })
        return data;
    } catch (error) {
        throw new Error(error);
    }
}
export const getItemById = async (id: number) => {
    try {
        if(!id) return null;
        const { data } = await axios.get('/Companies/'+ id)
        return data.name;
    } catch (error) {
        throw new Error(error);
    }
}

export const getCount = async () => {
    try {
        const {data} = await axios.get('/Companies/count', {
            params: {
                where: {},
            },
        });
        return data?.count || 0;
    } catch (error) {
        throw new Error(error);
    }
}

export const deleteCompanies = async (ids: number[]) => {
    try {
        await Promise.all(ids.map((id: number) => axios.delete(`/Companies/${id}`)));
    } catch (error) {
        throw new Error(error);
    }
};

export const createCompany = async (company: Company) => {
    try {
        const { data } = await axios.post('/Companies', company);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const patchCompany = async (company: Company) => {
    try {
        const { data } = await axios.patch(`/Companies/${company.id}`, company);
        return data;
    } catch (error) {
        throw new Error(error);
    }
}