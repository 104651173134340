import { DataPush, DataSync } from "../types";
import axios from "axios";

export const pushToWP = (data: DataPush, url?: string) => {
    const _url = url || 'http://localhost/wordpress/wp-json/ccpplugin/v1/create_post';
    return axios.post(_url, data)
        .then(response => response)
        .catch(error => console.log(error));
}

export const syncToWP = (data: DataSync, url?: string) => {
    const _url = url || 'http://localhost/wordpress/wp-json/ccpplugin/v1/update_post';
    axios.post(_url, data)
        .then(response => console.log('sync ok',_url))
        .catch(error => console.log('sync fail',_url, error));
}
export const upsertToWP = async (data: DataSync, url?: string) => {
    const _url = url || 'http://localhost/wordpress/wp-json/ccpplugin/v1/update_post';
    let result;
    await axios.post(_url, data)
        .then(response => {
            const successMessage = `Upsert successful: ${JSON.stringify(data)}, URL: ${_url}, Response: ${JSON.stringify(response.data)}`;
            console.log(successMessage);
            result = response.data;
        })
        .catch(error => {
            const errorMessage = `Upsert failed: ${JSON.stringify(data)}, URL: ${_url}, Error: ${error}`;
            console.error(errorMessage);
            result = error; 
        });
    return result;

}
export const deleteFromWP = async (data: any, url?: string) => {
    const _url = url || 'http://localhost/wordpress/wp-json/ccpplugin/v1/update_post';
    let result;
    await axios.post(_url, data)
    .then(response => {
    const successMessage = `Delete successful: ${JSON.stringify(data)}, URL: ${_url}, Response: ${JSON.stringify(response.data)}`;
    console.log(successMessage);
    result = response.data;
    })
    .catch(error => {
    const errorMessage = `Delete failed: ${JSON.stringify(data)}, URL: ${_url}, Error: ${error}`;
    console.error(errorMessage);
    result = error;
    });
    return result;
    };
export const duplicatePostWP = async (data: string[], url?: string) => {
    let result: any[] = [];
    const _url = url || 'http://localhost/wordpress/wp-json/ccpplugin/v1/duplicate_post';
    await axios.post(_url, data)
        .then(response => result = response.data)
        .catch(error => console.log(error));
    return result;
}
// export const pushToWP = (data: DataPush, url?: string) => {
//     const _url = url || 'http://localhost/wordpress/wp-json/ccpplugin/v1/create_post'
//     fetch(_url, {
//         method: 'POST', // or 'PUT'
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(data),
//     })
//         .then(response => response.json())
//         .then(data => {
//             console.log('Success:', data);
//         })
//         .catch((error) => {
//             console.error('Error:', error);
//         });
// }
