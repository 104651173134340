import { ContentHub } from '@/types';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Table,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';

type Prop = {
  contentHub: ContentHub[];
  pushToDBEvent: Function;
};

const EditTable = (props: Prop) => {
  const { Search } = Input;
  const [form] = Form.useForm();

  const [data, setData] = useState<ContentHub[]>([]);
  const [editingKey, setEditingKey] = useState('');
  const [showEditTableCell, setShowEditTableCell] = useState(false);

  const cancel = () => {
    setEditingKey('');
  };

  const showEditTableCellEvent = () => {
    setShowEditTableCell(false);
  };

  const onSearch = (value: string) => {};

  const columns = [
    { title: 'ID', dataIndex: 'content_id', editable: true, width: '90px' },
    { title: 'SLUG', dataIndex: 'slug', editable: true, width: '180px' },
    { title: 'IMAGE', dataIndex: 'image', editable: true },
    {
      title: 'CATEGORY',
      dataIndex: 'category',
      editable: true,
      width: '120px',
    },
    { title: 'GENDER', dataIndex: 'gender', editable: true, width: '120px' },
    { title: 'TAGS', dataIndex: 'tags', editable: true, width: '180px' },
    { title: 'USED', dataIndex: 'used', editable: true, width: '80px' },
    { title: 'TITLE_EN', dataIndex: 'title_en', editable: true },
    { title: 'DESCRIPTION_EN', dataIndex: 'description_en', editable: true },
    { title: 'TITLE_ES', dataIndex: 'title_es', editable: true },
    { title: 'DESCRIPTION_ES', dataIndex: 'description_es', editable: true },
    { title: 'TITLE_IT', dataIndex: 'title_it', editable: true },
    { title: 'DESCRIPTION_IT', dataIndex: 'description_it', editable: true },
    { title: 'TITLE_FR', dataIndex: 'title_fr', editable: true },
    { title: 'DESCRIPTION_FR', dataIndex: 'description_fr', editable: true },
    { title: 'TITLE_CH', dataIndex: 'title_ch', editable: true },
    { title: 'DESCRIPTION_CH', dataIndex: 'description_ch', editable: true },
    { title: 'TITLE_PT', dataIndex: 'title_pt', editable: true },
    { title: 'DESCRIPTION_PT', dataIndex: 'description_pt', editable: true },
    // {
    //   title: 'operation',
    //   dataIndex: 'operation',
    //   width: '120px',
    //   fixed: 'right',
    //   render: (_: any, record: Item) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <span>
    //         <Typography.Link
    //           onClick={() => save(record.key)}
    //           style={{ marginRight: 8 }}
    //         >
    //           Save
    //         </Typography.Link>
    //         <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
    //           <a>Cancel</a>
    //         </Popconfirm>
    //       </span>
    //     ) : (
    //       <div>
    //         <Typography.Link
    //           disabled={editingKey !== ''}
    //           onClick={() => edit(record)}
    //         >
    //           Edit
    //         </Typography.Link>
    //         <Typography.Link
    //           disabled={editingKey !== ''}
    //           onClick={() => edit(record)}
    //         >
    //           Delete
    //         </Typography.Link>
    //       </div>
    //     );
    //   },
    // },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      // onCell: (record: Item) => ({
      //   record,
      //   inputType: col.dataIndex === 'age' ? 'number' : 'text',
      //   dataIndex: col.dataIndex,
      //   title: col.title,
      //   editing: isEditing(record),
      // }),
    };
  });

  const pushToDBEvent = (): void => {
    props.pushToDBEvent();
  };

  useEffect(() => {
    const newData = props.contentHub.map((item: ContentHub, index: number) => {
      return {
        ...item,
        key: index,
      };
    });
    setData(newData);

    return () => {
      setData([]);
    };
  }, [props.contentHub]);

  return (
    <div>
      <Button onClick={pushToDBEvent}>Push to DB</Button>
      <div style={{height:8}}/>
      <Form form={form} component={false}>
        <Table
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
          scroll={{ y: 840, x: 4000 }}
        />
      </Form>
    </div>
  );
};

export default EditTable;
