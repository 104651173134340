import { CategoriesInput } from './../types';
import axios from 'axios';
import config from '../../config';
import { ContentHub } from '@/types';

axios.defaults.baseURL = config.BASE_URL;

export const getContents = async (filter: any) => {
    try {
        const result = await axios.get('/ContentHubs/getAndShuffle', {
            params: {
                filter: JSON.stringify(filter ? filter : {}),
            }
        });
        if (result && result.data && result.data.result) {
            return result.data.result;
        }
        return [];
    } catch (error) {
        throw new Error(error);
    }
}

export const postContent = async (json: string) => {
    try {
        const { data } = await axios.post('/ContentHubs/postExcel', {
            json
        }) as {
            data: {
                result: {
                    count?: number;
                    data?: string[];
                    status: number;
                }
            }
        };
        return data?.result;
    } catch (error) {
        throw new Error(error);
    }
}

export const patchContentHub = async (contentHub: ContentHub) => {
    try {
        const { data } = await axios.patch('/ContentHubs', contentHub);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const upsertAllCategories = async (input: CategoriesInput) => {
    try {
        const { data } = await axios.post('/ContentHubs/upsertAllCategories', { input });
        return data;
    } catch (error) {
        throw new Error(error);
    }

}