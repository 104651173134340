import axios from 'axios'
import { Filter } from '@/types'

export interface PushHistory {
    posts: { id: number, status: string }[]
    languages: string[]
    portals: number[]
    createTime: Date
    status: string;
    userId: string;
}

export const getList = async (filter: Filter = {}) => {
    try {
        const { data } = await axios.get('/PushHistories', {
            params: {
                filter,
            },
        });
        return data as PushHistory[];
    } catch (error) {
        throw new Error(error);
    }
};

export const getCount = async () => {
    try {
        const { data } = await axios.get('/PushHistories/count', {
            params: {
                where: {},
            },
        });
        return data?.count || 0;
    } catch (error) {
        throw new Error(error);
    }
};

export const getPortalName = async (id) => {
    try {
        const { data } = await axios.get(`/Portals/${id}`);
        return data.name;
    } catch (error) {
        throw new Error(error);
    }
}