import PushHistoryPage from '@/pages/PushHistory';
import { PushHistory } from '@/pages/PushHistory/push-history-service';
import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';

type Prop = {
    showPortalsDropdownEvt?: (data: PushHistory) => void;
}

const ReuseDataPusher = (props: Prop) => {
    const [showTable, setShowTable] = useState<boolean>(false);
    // const [showPortalsDropdown, setPortalsDropdown] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            setShowTable(false);
        }
    }, [])

    return (
        <div className='reuse-data'>
            <a href='' onClick={(e) => { e.preventDefault(); setShowTable(!showTable) }}>I want to re use data pushed previously</a>

            {
                showTable && <PushHistoryPage
                    type='reuse_data_pushed'
                    showPortalsDropdownEvt={props.showPortalsDropdownEvt}
                />
            }

        </div>
    )
}

ReuseDataPusher.defaultProps = {

};

export default ReuseDataPusher;