import { Portal } from '@/pages/Portal/types';
import { Modal, Select } from 'antd';
import React, { useState } from 'react';

type Prop = {
    isModalOpen: boolean;
    handleOk?: () => void;
    handleCancel?: () => void;
    handlePortalChange?: (portalIds: number[]) => void;
    portalList: Portal[];
}
const PortalsDropdownModal = (props: Prop) => {
    return (
        <Modal title="Portals Dropdown"
            open={props.isModalOpen}
            onOk={props.handleOk}
            onCancel={props.handleCancel}
        >
            <Select
                showSearch
                // @ts-ignore
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                placeholder="Please select"
                mode="multiple"
                style={{ width: 400 }}
                defaultValue={[14]}
                onChange={props.handlePortalChange}
                options={props.portalList.map(e => {
                    return {
                        value: e.id,
                        label: e.name
                    }
                })}
            />
        </Modal>
    )
}

export default PortalsDropdownModal;
