import { ContentHub, Filter } from './../../../types';
import axios from 'axios';

export const getList = async (filter: Filter = {}) => {
    try {
        const { data } = await axios.get('/ContentHubs', {
            params: {
                filter,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getCount = async (filter: Filter = { where:{}}) => {
    try {
        const { data } = await axios.get('/ContentHubs/count', {
            params: {
                where: filter.where,
            },
        });
        return data?.count || 0;
    } catch (error) {
        throw new Error(error);
    }
};

const getCategories = async () => {
    try {
        const { data } = await axios.get('/Categories/', {
            params: {
                where: {},
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

const getTags = async () => {
    try {
        const { data } = await axios.get('/Tags', {
            params: {
                where: {},
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

const getGender = async () => {
    try {
        const { data } = await axios.get('/Genders', {
            params: {
                where: {},
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getTagsCategoriesGenders = async () => {
    const result = await Promise.all([getTags(), getCategories(), getGender()]);
    return result;
};

export const updateContentHub = async (contentHub: ContentHub) => {
    // chuẩn hóa dữ liệu used và used_count cho contentHub
    const splitAndUniqueUrls = (urlString) => {
        // Tách chuỗi bằng cách sử dụng dấu phẩy hoặc dấu gạch chéo kép (//) làm điểm phân tách
        let urls = urlString.split(/(?=https:\/\/)|,/);
        // Loại bỏ các phần tử rỗng nếu có
        urls = urls.filter(url => url.trim() !== '');
        // Loại bỏ các địa chỉ trùng lặp
        let uniqueUrls = [...new Set(urls)];
        // Nối các URL lại với nhau bằng dấu phẩy
        return uniqueUrls.join(',');
    }

    contentHub.used = splitAndUniqueUrls(contentHub.used);
    contentHub.used_count = contentHub.used.split(',').length;

    try {
        const { data } = await axios.patch('/ContentHubs', contentHub);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const deleteContentHubs = async (ids: number[]) => {
    try {
        await Promise.all(ids.map((id: number) => axios.delete(`/ContentHubs/${id}`)));
    } catch (error) {
        throw new Error(error);
    }
};
