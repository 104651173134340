import { ContentHub } from '@/types';
import { Portal } from '../Portal/types';

export const getContentCanPushAndExist = (
  selectedContentHubs: ContentHub[],
  selectedPortal: Portal[]
) => {
  const contentsWithUsedColumn = selectedContentHubs.map((content) => {
    const used = content?.used?.split(',') || [];
    const removeDup = [...new Set(used)];
    return {
      ...content,
      usedRemoveDup: removeDup,
    };
  });
  const contentDup = contentsWithUsedColumn.map((content) => {
    const usedPortals: string[] = [];
    selectedPortal.forEach((portal) => {
      if (portal?.name && content?.usedRemoveDup?.includes(portal?.name)) {
        usedPortals.push(portal.name);
      }
    });
    return {
      ...content,
      usedPortals,
    };
  });

  const canPush = contentDup?.filter((post) => post.usedPortals?.length === 0);
  const denyPush = contentDup?.filter((post) => post.usedPortals?.length > 0);

  // update setSelectedContentHubs for push
  const canPushIds = (canPush.map(content => content.content_id) || []) as string[];
  const temp = [...selectedContentHubs].map(content => {
    if (canPushIds.includes(content?.content_id as string)) {
      return {
        ...content,
        canPush: true,
      }
    }
    return {
      ...content,
      canPush: false,
    }
  });

  return {
    canPush,
    denyPush,
    contentsWithCanPushState: temp,
  };
};
