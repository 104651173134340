import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from '@/app';
import { GlobalStyles } from '@/global-styles';
import config from '../config';
import axios from 'axios';
import ApiService from '@/services/api.service';
import JwtService from '@/services/jwt.service';

const queryClient = new QueryClient();

const container = document.getElementById('app');
const root = createRoot(container!);

axios.defaults.baseURL = config.BASE_URL;
ApiService.setAxiosGlobal(`${JwtService.getToken()}`);

root.render(
  // <React.StrictMode>
    <React.Suspense fallback={<></>}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <GlobalStyles />
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </React.Suspense>
  // </React.StrictMode>
);
