import React from 'react';
import ContentTable from '@/pages/components/ContentTable';
import { ContentHub } from '@/types';
import { DataSync } from '../../types';
import { Company as CompanyType } from '@/pages/Company/types';
import { Portal } from '@/pages/Portal/types';

type Prop = {
  companyList?: CompanyType[];
  portalList?: Portal[];
  type?: string;
  page: number;
  total: number;
  setTotal?: Function;
  pageSize: number;
  data: ContentHub[];
  setPagination: Function;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: Function;
  searchText?: string;
  setSearchText?: Function;
  searchedColumn?: string;
  setSearchedColumn?: Function;
  viewDetailEvent: (id: number) => void;
  addToCartEvt?: (id: number) => void;
  removeFromCartEvt?: (id: number) => void;

};

const ListView = (props: Prop) => {
  const addToCartEvt = (id: number) => {
    if (props.addToCartEvt) {
      props.addToCartEvt(id);
    }
  }

  return (
    <ContentTable
      portalList={props.portalList}
      companyList={props.companyList}
      type={props.type}
      data={props.data}
      page={props.page}
      total={props.total}
      setTotal={props.setTotal}
      pageSize={props.pageSize}
      setPagination={props.setPagination}
      selectedRowKeys={props.selectedRowKeys}
      setSelectedRowKeys={props.setSelectedRowKeys}
      searchText={props.searchText}
      setSearchText={props.setSearchText}
      searchedColumn={props.searchedColumn}
      setSearchedColumn={props.setSearchedColumn}
      viewDetailEvent={props.viewDetailEvent}
      addToCartEvt={addToCartEvt}
      removeFromCartEvt={props.removeFromCartEvt}
    />
  );
};

export default ListView;
