import { getList } from '@/pages/Company/services/company.services';
import { Company } from '@/pages/Company/types';
import { Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Portal } from '../types';

const StyledInput = styled(Input)`
    span.ant-input-group-addon {
      width: 140px
    }
`

type Prop = {
  portalForm: Portal;
  formChange: Function;
};

const PortalModal = (props: Prop) => {

  const [companyList, setCompanyList] = useState<Company[]>([]);
  const handleOnChange = (event: any, type: 'name' | 'url') => {
    const value = event.target.value;
    props.formChange({ ...props.portalForm, [type]: value });
  };
  const handleOnChangeSelect = (value) => {
    props.formChange({ ...props.portalForm, companyId: value});
  }
  useEffect(() => {
    getList().then((res) => {
      setCompanyList(res);
    });
  }, []);
  return (
    <div>
      <StyledInput
        addonBefore={'name'}
        value={props.portalForm?.name}
        onChange={(event) => handleOnChange(event, 'name')}
        style={{marginBottom: 6}}
      />
      <StyledInput
        addonBefore={'url'}
        value={props.portalForm?.url}
        onChange={(event) => handleOnChange(event, 'url')}
        style={{marginBottom: 6}}
      />
        <Select
                style={{ width: '100%', paddingLeft: '140px',  }}
                value={props.portalForm?.companyId}
                showSearch
                filterOption={(input, option) => (option?.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())}
                placeholder="Please select company"
                onChange={handleOnChangeSelect}
                options={[{value: null, label: '--Select--'},...companyList.map(e => {
                  return {
                    value: e.id,
                    label: e.name
                  }
                })]}
          />
    </div>
  );
};

export default PortalModal;
