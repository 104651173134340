import React from 'react';
import { RouteObject, useParams } from 'react-router-dom';

import FillIn from './pages/FillIn/fill-in';
import Login from './pages/Login/login';
import NotFoundPage from '@/pages/404';
import PublicLayout from '@/layouts/public-layout';
import UserLayout from '@/layouts/user-layout';
import EditDatabase from '@/pages/Edit/edit-database';
import ViewAndPush from './pages/ViewAndPush/view-and-push';
import Portal from './pages/Portal/portal';
import Tag from './pages/Tag/tag';
import PushHistoryPage from '@/pages/PushHistory'
import Company from './pages/Company/company';
import Account from './pages/Account/account';
import ToolsPage from '@/pages/Tools/tools'

// ví dụ trích param từ url
const PageWithId = () => {
  const params = useParams();
  return (
    <>
      <h1>POST DETAIL PAGE</h1>
    </>
  );
};

const routes: RouteObject[] = [
  {
    path: '/',
    element: <PublicLayout />,
    children: [{ index: true, element: <Login /> }],
  },
  {
    path: 'admin',
    element: <UserLayout />,
    children: [
      { index: true, element: <FillIn /> },

      {
        path: 'edit-database',
        element: <EditDatabase />,
      },
      {
        path: 'view-and-push',
        element: <ViewAndPush />,
      },
      {
        path: 'portal',
        element: <Portal />,
      },
      {
        path: 'tag',
        element: <Tag />,
      },
      {
        path: 'company',
        element: <Company />,
      },
      {
        path: 'push-history',
        element: <PushHistoryPage />,
      },
      {
        path: 'tools',
        element: <ToolsPage />,
      },
      // {
      //     path: '/post/:id', element: <PageWithId/>
      // },
      {
        path: 'account',
        element: <Account />,
      },
    ],
  },
  { path: '*', element: <NotFoundPage /> },
];

export default routes;
