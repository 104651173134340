import React, { useState, useEffect,  } from 'react'
import { Button, Table, Tag, Progress } from 'antd'
import { useQuery } from 'react-query'
import { getPortalName, getCount, getList, PushHistory } from './push-history-service'
import { getList as getPortals } from '../Portal/services/portal.services';
import { Portal } from '../Portal/types';
import PushHistoryDetail from './push-history-detail';
import { getList as getListCompany } from '../Company/services/company.services';
import { Company as CompanyType } from '../Company/types';

type Prop = {
    type?: string;
    showPortalsDropdownEvt?: (data: PushHistory) => void;
}

const PushHistoryPage = (props: Prop) => {
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(500);
    const [companyList, setCompanyList] = useState<CompanyType[]>([]);

    const { data: total } = useQuery('countPushHistory', getCount);
    const { isLoading, isError, error, refetch, data: dataList, } = useQuery(
        ['listPushHistory', { skip: (page - 1) * pageSize, limit: pageSize }], () =>
        getList({ skip: (page - 1) * pageSize, limit: pageSize })
    );
    const [listPortals, setListPortals] = useState<Portal[]>([]);
    useQuery(
        ['getPortalsList'], () =>
        getPortals(),
        {
            onSuccess: (data) => {
                if (data) {
                    setListPortals(data)
                }
            }
        }
    );
    const [viewHistoryDetail, setViewHistoryDetail] = useState<boolean>(false);
    const [historyDetail, setHistoryDetail] = useState<PushHistory | Record<string, any>>({});

    const PortalNameTag = ({ id }: { id: number }) => {
        const portal: Record<string, any> = listPortals.find(portal => portal.id === id) || {};
        return <Tag>{portal?.name}</Tag>;
    }

    const onViewDetail = (data: PushHistory) => {
        setHistoryDetail(data);
        setViewHistoryDetail(true);
    }

    const getPercentStatus = (status: string) => {
        const value = status.split('/');
        if (value.length === 2) {
            return Math.round(Number(value[0] || 0) * 100 / Number(value[1] || 1));
        }
        return 0;
    }

    const columns: Record<string, any>[] = [
        {
            title: 'Publish Time',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (value, record, index) => {
                return (new Date(value)).toLocaleString()
            }
        },
        {
            title: 'Portals',
            dataIndex: 'portals',
            key: 'portals',
            render: (value, record, index) => {
                return value.map((portalId: number, i) => (<PortalNameTag key={`portal-${index}-${i}`} id={portalId} />))
            }
        },
        {
            title: 'Languages',
            dataIndex: 'languages',
            key: 'languages',
            render: (value, record, index) => {
                return value.map((lang, i) => <Tag key={`language-${index}-${i}`}>{lang}</Tag>)
            }
        },
        // {
        //     title: 'Posts',
        //     dataIndex: 'posts',
        //     key: 'posts',
        //     render: (value, record, index)=> {
        //         return JSON.stringify(value)
        //     }
        // },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '160px',
            render: (_, record: PushHistory) => (
                <Progress percent={getPercentStatus(record.status)} status="active" />
            )
        },
    ];

    if (props.type === 'reuse_data_pushed') {
        columns.push({
            title: 'Actions',
            dataIndex: 'actions',
            width: '120px',
            key: 'actions',
            render: (_, record: PushHistory) => (
                <>
                    <Button type='primary' onClick={() => onViewDetail(record)}> View </Button >
                    <Button onClick={() => props.showPortalsDropdownEvt && props.showPortalsDropdownEvt(record)}> Select </Button>
                </>
            )
        },)
    } else {
        columns.push({
            title: 'Actions',
            dataIndex: 'actions',
            width: '120px',
            key: 'actions',
            render: (_, record: PushHistory) => (
                <>
                    <Button type='primary' onClick={() => onViewDetail(record)}> View </Button >
                </>
            )
        })
    }

    const setModalVisibleEvt = (state: boolean) => {
        setViewHistoryDetail(state);
    }

    useEffect(() => {
        getListCompany().then(res => setCompanyList(res));
      }, []);

    return <div style={{  }}>
        <Table rowKey="createTime" dataSource={dataList} columns={columns}
            pagination={{
                position: ['bottomLeft', 'topLeft'],
                current: page,
                pageSize: pageSize,
                total: total,
                onChange: (page, pageSize) => {
                    setPage(page);
                    setPageSize(pageSize);
                },
            }}
        />

        <PushHistoryDetail
            visible={viewHistoryDetail}
            historyDetail={historyDetail}
            setVisible={setModalVisibleEvt}
            listPortals={listPortals}
            companyList={companyList}
        />
    </div>
}

export default PushHistoryPage
