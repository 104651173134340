import { Filter } from '../../../types';
import axios from 'axios';
import { Tag } from '../types';

export const getList = async (filter: Filter = {}) => {
    try {
        const { data } = await axios.get('/Tags', {
            params: {
                filter,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getCount = async () => {
    try {
        const { data } = await axios.get('/Tags/count', {
            params: {
                where: {},
            },
        });
        return data?.count || 0;
    } catch (error) {
        throw new Error(error);
    }
};

export const deleteTags = async (ids: number[]) => {
    try {
        await Promise.all(ids.map((id: number) => axios.delete(`/Tags/${id}`)));
    } catch (error) {
        throw new Error(error);
    }
};

export const createTag = async (tag: Tag) => {
    try {
        const { data } = await axios.post('/Tags', tag);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const patchTag = async (tag: Tag) => {
    try {
        const { data } = await axios.patch(`/Tags/${tag.id}`, tag);
        return data;
    } catch (error) {
        throw new Error(error);
    }
}