import { ContentHub } from '@/types';
import { Button, Modal } from 'antd';
import { default as React, useState } from 'react';
import styled from 'styled-components';
import ListView from '../components/ListView/list-view';

const CartModalActionStyle = styled.div`
    gap: 10px;
    display: flex;
    justify-content: end;
`;

type Prop = {
    pageSize: number;
    currentPageCart: number;
    cartItems: ContentHub[];
    isCartModalOpen: boolean;
    selectedRowKeysCart: number[];
    discardAllEvt: () => void;
    checkAllCartEvt: () => void;
    unCheckAllCartEvt: () => void;
    viewDetailEvent: (id: number) => void;
    removeFromCartEvt: (id: number) => void;
    handleSelectedRowKeysCart: (keys: number[]) => void;
    setPaginationEvtCart: (data: { page: number, pageSize: number }) => void;
    setCartIsModalOpenEvt: (status: boolean) => void;
    setShowPushSettingEvt: (status: boolean) => void;
}
const CartModal = (props: Prop) => {
    const footerCustom = [
        <Button key='cancel' type="default" onClick={() => props.setCartIsModalOpenEvt(false)}>
            Cancel
        </Button>
    ];

    if (props.cartItems.length) {
        footerCustom.push(
            <Button key='push' type="primary" onClick={() => props.setShowPushSettingEvt(true)}>
                Push
            </Button>
        )
    }
    return (
        <Modal
            title={"List contents for Push"}
            open={props.isCartModalOpen}
            onCancel={() => props.setCartIsModalOpenEvt(false)}
            footer={footerCustom}
            width={1200}
            destroyOnClose={true}
        >
            <>
                { !!props.cartItems.length && <p>Total: <b>{props.cartItems.length}</b> posts</p> }
                { !!props.selectedRowKeysCart.length && <p>Selected: <b>{props.selectedRowKeysCart.length}</b> posts</p> }
                <CartModalActionStyle>
                    {
                        !!props.cartItems.length && <Button type="primary" danger onClick={props.discardAllEvt}>
                            Discard all
                        </Button>
                    }
                    <Button type="default" onClick={props.unCheckAllCartEvt}>
                        Uncheck all
                    </Button>
                    <Button type="default" onClick={props.checkAllCartEvt}>
                        Check all
                    </Button>
                </CartModalActionStyle>
                <ListView
                    type='cart_modal'
                    data={props.cartItems}
                    page={props.currentPageCart}
                    total={props.cartItems.length}
                    pageSize={props.pageSize}
                    selectedRowKeys={props.selectedRowKeysCart}
                    setPagination={props.setPaginationEvtCart}
                    setSelectedRowKeys={props.handleSelectedRowKeysCart}
                    viewDetailEvent={props.viewDetailEvent}
                    removeFromCartEvt={props.removeFromCartEvt}
                />
            </>
        </Modal>
    )
}

export default CartModal;