import { Button, Modal, Table, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { Portal } from '../Portal/types';
import { PushHistory } from './push-history-service';
import { getList as getPostsList } from '../../pages/Edit/services/edit-database';
import { ContentHub } from '@/types';
import PushHistoryDetailPosts from './push-history-detail-posts';
import EditTableCell from '../Edit/components/edit-table-cell';
import { Company as CompanyType } from '../Company/types';

type Prop = {
    visible: boolean;
    setVisible: (state: boolean) => void;
    historyDetail: PushHistory | Record<string, any>;
    listPortals: Portal[];
    companyList: CompanyType[];
}

const PushHistoryDetail = (props: Prop) => {
    const [postsDetail, setPostsDetail] = useState<ContentHub[]>([]);

    const getPortalsInfo = (ids: number[]) => {
        if (!props.visible) {
            return;
        }
        const infos = props.listPortals.filter(portal => portal && ids.includes(portal.id));
        return (
            <>
                {
                    infos.map((info, index) => (
                        <p key={'infos' + index}>{info.name}</p>
                    ))
                }
            </>
        );
    }

    const getPostsDetails = async () => {
        if (!props.visible) {
            return '';
        }
        const ids = props.historyDetail.posts.map(item => item.id);
        const result = await getPostsList({
            where: {
                id: {
                    inq: ids
                }
            }
        })
        setPostsDetail(result);
    }

    const [postDetail, setPostDetail] = useState<ContentHub>();

    const viewDetailEvt = (item: ContentHub) => {
        setPostDetail(item);
    }

    const showEditTableCellEvent = () => {
        setPostDetail(undefined);
    }

    useEffect(() => {
        if (props.visible) {
            getPostsDetails();
        }
    }, [props.visible]);

    return (
        <>
            <Modal width={1200} title="Push History Detail" destroyOnClose={true} open={props.visible} onOk={() => props.setVisible(false)} onCancel={() => props.setVisible(false)}>
                <>
                    <p><b>Create time:</b> {(new Date(props.historyDetail.createTime)).toLocaleString()} </p>
                    <p><b>Languages:</b> {props?.historyDetail?.languages?.toString()}</p>
                    <p><b>Portals:</b> </p>
                    {getPortalsInfo(props.historyDetail.portals)}
                    <p><b>Status: </b>{props.historyDetail.status}</p>
                    <p><b>Posts:</b> {postsDetail.length}</p>
                    {
                        !!postsDetail.length && <PushHistoryDetailPosts data={postsDetail} total={postsDetail.length} viewDetailEvt={viewDetailEvt} />
                    }
                </>
            </Modal>
            {
                postDetail && <EditTableCell
                    data={postDetail}
                    showVisible={!!postDetail}
                    type={'push_history_detail'}
                    showVisibleEvent={showEditTableCellEvent}
                    portalList={props.listPortals}
                    companyList={props.companyList}
                />
            }
        </>
    )
}

export default PushHistoryDetail;