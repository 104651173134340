import React, { useEffect, useState } from "react";
import { Button, Modal, Input, Form, Skeleton, notification } from 'antd';
import { deleteUser, getUserCount, getUsers, patchUser, postUser } from "./services/account-service";
import { useQuery } from "react-query";
import AccountTable from "./components/account-table";
import type { NotificationPlacement } from 'antd/es/notification/interface';
import AccountModal from "./components/account-modal";

const Account = () => {
    // modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [accountInfo, setAccountInfo] = useState<Record<string, any>>({});

    const showModal = () => {
        setAccountInfo({});
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [apiNotiError, contextHolder] = notification.useNotification();

    let notificationError: string = '';
    const openNotification = (placement: NotificationPlacement) => {
        apiNotiError.error({
            message: `Error when create account`,
            description: notificationError,
            placement,
        });
    };

    const onFinish = async (values: Record<string, any>) => {
        const { confirm, ...rest } = values;
        if (rest.id) {
            await patchUser(rest);
            setIsModalOpen(false);
            return;
        }
        const user = await postUser({
            ...rest,
            isActive: true,
            roleId: 3,
        })
        if (user.error) {
            notificationError = user.error;
            openNotification('top');
        } else {
            setIsModalOpen(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    // table
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);

    const { data: total } = useQuery("user-count", () => getUserCount({ where: {  } }));
    const {
        isLoading,
        isError,
        error,
        refetch,
        data: listUsers,
    } = useQuery(
        ["get-users", { where: {  }, skip: (page - 1) * pageSize, limit: pageSize, order: 'id desc' }],
        () => getUsers({ where: {  }, skip: (page - 1) * pageSize, limit: pageSize, order: 'id desc' })
    );

    const setPaginationEvent = (data: { page: number; pageSize: number }) => {
        setPage(data.page);
        setPageSize(data.pageSize);
    };

    const deleteEvt = async (id: number) => {
        try {

            Modal.confirm({
                title: 'Warning...',
                content: 'Do you want delete this user!',
                okText: 'Yes',
                cancelText: 'No',
                onOk: async () => {
                    await deleteUser(id);
                    refetch();
                }
            });
        } catch (error) {
            console.log('error', error);
        }
    }

    const viewEvt = (record: any) => {
        setAccountInfo(record);
        setIsModalOpen(true);
    }

    if (isLoading) {
        return (
            <div>
                <Skeleton />
            </div>
        );
    }
    if (isError) {
        return <div>Error {error as any}</div>;
    }

    return (
        <div className="account-wrapper">
            <div className="account-wrapper__action">
                <Button type="primary" onClick={showModal}>Add Account</Button>
            </div>
            <div className="account-wrapper__content">
                <AccountTable
                    page={page}
                    total={total}
                    data={listUsers}
                    pageSize={pageSize}
                    setPagination={setPaginationEvent}
                    deleteEvt={deleteEvt}
                    viewEvt={viewEvt}
                />
            </div>
            {contextHolder}
            <AccountModal
                accountInfo={accountInfo}
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            />
        </div>
    )
}

export default Account;