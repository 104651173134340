import React from 'react';
import { Card, Checkbox, Button } from 'antd';
import { ContentHub } from '@/types';
const { Meta } = Card;

type Prop = {
  data: ContentHub;
  selectedRowKeys: React.Key[];
  clickCardEvent: (id: number) => void;
  viewDetailEvent: (id: number) => void;
};

const CardViewListItem = (props: Prop) => {
  const onClickCard = () => {
    props.clickCardEvent(props.data.id);
  };

  const viewDetailEvent = (e: Event | any): void => {
    e.stopPropagation();
    props.viewDetailEvent(props.data.id);
  }

  const colorBaseMaxTimes = (timesUsed: number = 0) => {
    const prefix = 'card-view__image--';
    if (timesUsed > 20) {
      return prefix + 'red';
    }
    if (timesUsed < 10) {
      return prefix + 'green';
    }
    return prefix + 'orange';
  };

  return (
    <>
      <Card
        className="card-view"
        hoverable
        cover={
          <div className='card-view__wrapper-image'>
             <img alt="example" className={ `card-view__image ${colorBaseMaxTimes(props.data.used_count)}` } src={props.data.image} />
          </div>
        }
        style={{
          opacity: props.selectedRowKeys.includes(props.data.id) ? 0.8 : 1,
          minHeight: "450px",
          borderColor: props.selectedRowKeys.includes(props.data.id) ? "#9ecaed": "",
          boxShadow: props.selectedRowKeys.includes(props.data.id) ? "0 0 10px #9ecaed" :"",
        }}
        onClick={onClickCard}
      >
        <Meta title={props.data.title_en}/>
        <p></p>
        <p>
          <b>Category:</b> {props.data.category}
        </p>
        <p>
          <b>Tags:</b> {props.data.tags}
        </p>
        <p>
          <b>Genders:</b> {props.data.gender}
        </p>
        <p style={{ display: 'flex', justifyContent: 'space-around'}}>
          <b>{props.data.used_count}</b> TIMES used
          <Checkbox checked={props.selectedRowKeys.includes(props.data.id)}></Checkbox>
        </p>
        <p style={{textAlign: "right"}}>
        </p>
        <Button onClick={viewDetailEvent}>View Detail</Button>
      </Card>
    </>
  );
};
export default CardViewListItem;
