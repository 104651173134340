import ContentTable from '@/pages/components/ContentTable';
import { ContentHub } from '@/types';
import React, { useState } from 'react';
import EditTableActions from './edit-table-actions';
import EditTableCell from './edit-table-cell';
import { Company as CompanyType } from '../../Company/types';
import { Portal } from '@/pages/Portal/types';

type Prop = {
  total: number;
  page: number;
  pageSize: number;
  data: ContentHub[];
  setPagination: Function;
  setData: Function;
  viewDetailEvent?: Function;
  type?: 'edit-database' | string;
  disableSearch?: boolean;
  deleteRowEvent?: (id: number) => void;
  companyList: CompanyType[];
  portalList: Portal[];
  loadingTable?: boolean;
};

const EditTable = (props: Prop) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [showEditTableCell, setShowEditTableCell] = useState(false);
  const [tableRowData, setTableRowData] = useState<ContentHub>({ id: 0 });

  const showEditTableCellEvent = (contentUpdate?: ContentHub, action?: 'UPDATE' | 'DELETE' | 'SYNC') => {
    if (contentUpdate && action) {
      props.setData({
        data: contentUpdate,
        action,
      });
    }
    setSelectedRowKeys([]);
    setShowEditTableCell(false);
  };

  const deleteRowEvent = (id: number) => {
    props.deleteRowEvent && props.deleteRowEvent(id);
  }

  const doAction = (action: string) => {
    if (action === 'UNCHECK_ALL') {
      setSelectedRowKeys([]);
      return;
    }
    if (action === 'DELETE') {
      props.setData({
        data: selectedRowKeys,
        action: 'DELETE',
      });
      return;
    }
    if (action === 'BOLD_HEADER') {
      props.setData({
        data: selectedRowKeys,
        action: 'BOLD_HEADER',
      });
      return;
    }
    if (action === 'edit') {
      setShowEditTableCell(true);
      const rowContent = props.data.find(
        (item: ContentHub) => item.id === selectedRowKeys[0]
      );
      if (rowContent) {
        setTableRowData(rowContent);
      }
    }
  };
  const viewDetailEvent = (id: number): void => {
    setShowEditTableCell(true);
    const rowContent = props.data.find(
      (item: ContentHub) => item.id === id
    );
    if (rowContent) {
      setTableRowData(rowContent);
    }
  }
  return (
    <div>
      <EditTableActions selectedRowKeys={selectedRowKeys} doAction={doAction} />
      <ContentTable
        disableSearch={props.disableSearch}
        type={props.type}
        data={props.data}
        page={props.page}
        total={props.total}
        pageSize={props.pageSize}
        selectedRowKeys={selectedRowKeys}
        setPagination={props.setPagination}
        loadingTable={props.loadingTable}
        setSelectedRowKeys={setSelectedRowKeys}
        viewDetailEvent={viewDetailEvent}
        deleteRowEvent={deleteRowEvent}

      />
      {showEditTableCell && (
        <EditTableCell
          type={props.type}
          showVisible={showEditTableCell}
          data={tableRowData}
          showVisibleEvent={showEditTableCellEvent}
          companyList={props.companyList}
          portalList={props.portalList}
        />
      )}
    </div>
  );
};

export default EditTable;
