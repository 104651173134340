import { Skeleton, Spin } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";
import CompanyActions from "./components/company-actions";
import CompanyModal from "./components/company-modal";
import CompanyTable from "./components/company-table";
import { createCompany, deleteCompanies, getCount, getList, patchCompany } from "./services/company.services";
import { Company as CompanyType } from "./types";
type Prop = {};
const Company: React.FC = (_: Prop) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [companyForm, setCompanyForm] = useState<CompanyType>({});
  const [formValid, setFormValid] = useState<boolean>(false);
  const [loadingFetch, setLoadingFetch] = useState<boolean>(false);

  const { data: total } = useQuery("count", getCount);
  const {
    isLoading,
    isError,
    error,
    refetch,
    data: dataList,
  } = useQuery(
    ["company-list", { skip: (page - 1) * pageSize, limit: pageSize, order: 'id desc' }],
    () => getList({ skip: (page - 1) * pageSize, limit: pageSize, order: 'id desc' })
  );

  if (isLoading) {
    return (
      <div>
        <Skeleton />
      </div>
    );
  }
  if (isError) {
    return <div>Error {error as any}</div>;
  }
  const setPaginationEvent = (data: { page: number; pageSize: number }) => {
    setPage(data.page);
    setPageSize(data.pageSize);
  };
  const setSelectedRowKeysEvent = (keys: number[]) => {
    setSelectedRowKeys(keys);
  };
  const companyFormChangeEvent = (form: CompanyType): void => {
    setCompanyForm(form);
    if (form.name !== '') {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };
  const onAddCompany = async () => {
    setLoadingFetch(true);
    await createCompany(companyForm);
    setFormValid(false);
    setCompanyForm({});
    refetch();
    setLoadingFetch(false);
  };

  const onDeleteEvent = async (): Promise<void> => {
    setLoadingFetch(true);
    await deleteCompanies(selectedRowKeys);
    refetch();
    setLoadingFetch(false);
  };

  const onEditEvent = (): void => {
    const company = dataList.find(
      (company: CompanyType) => company.id === selectedRowKeys[0]
    );
    if (company) {
      setCompanyForm(company);
    }
  };

  const onSaveEditEvent = async (): Promise<void> => {
    setLoadingFetch(true);
    await patchCompany(companyForm);
    setFormValid(false);
    setCompanyForm({});
    refetch();
    setLoadingFetch(false);
  };

  const onCancelEditEvent = (): void => {
    setCompanyForm({});
  };

  const uncheckAllEvent = (): void => {
    setSelectedRowKeys([]);
  };
  return (
    <div>
      <CompanyModal companyForm={companyForm} formChange={companyFormChangeEvent} />
      {loadingFetch && <Spin />}
      {!loadingFetch && (
        <CompanyActions
          formValid={formValid}
          selectedRowKeys={selectedRowKeys}
          onAddEvent={onAddCompany}
          onDeleteEvent={onDeleteEvent}
          onEditEvent={onEditEvent}
          onSaveEditEvent={onSaveEditEvent}
          onCancelEditEvent={onCancelEditEvent}
          uncheckAllEvent={uncheckAllEvent}
        />
      )}
      <CompanyTable
        page={page}
        total={total}
        data={dataList}
        pageSize={pageSize}
        selectedRowKeys={selectedRowKeys}
        setPagination={setPaginationEvent}
        setSelectedRowKeys={setSelectedRowKeysEvent}
      />
    </div>
  );
};

export default Company;
