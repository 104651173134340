import React, { useState } from 'react';
import { Checkbox } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import styled from 'styled-components';
import { CheckboxProps } from 'antd/lib';

const CustomCheckGroup = styled.div`
  & .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
`;

type Prop = {
  onChange: Function;
}

const FilterModal = (props: Prop) => {
  const plainOptions: string[] = [ 'en', 'es', 'it', 'fr', 'ch', 'pt'];
  const defaultCheckedList: string[] = ['en'];

  const [checkedList, setCheckedList] = useState<string[]>(defaultCheckedList);
  
  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;

  const onChange = (checkedValues: CheckboxValueType[]) => {
    props.onChange(checkedValues);
    setCheckedList(checkedValues as string[]);
  };

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    props.onChange(e.target.checked ? plainOptions : []);
  };

  return (
    <>
      <h3 className="text-uppercase">languages</h3>
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
        <b>Check/Uncheck all</b>
      </Checkbox>
      <p></p>
      <CustomCheckGroup>
        <Checkbox.Group
          options={plainOptions}
          defaultValue={defaultCheckedList}
          value={checkedList}
          onChange={onChange}
        />
      </CustomCheckGroup>
    </>
  );
};
export default FilterModal;
