import { Skeleton, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import PortalActions from './components/portal-actions';
import PortalModal from './components/portal-modal';
import PortalTable from './components/portal-table';
import {
  createPortal,
  deletePortals,
  getCount,
  getList,
  patchPortal,
} from './services/portal.services';
import { Portal as PortalType } from './types';
import { Company as CompanyType } from '../Company/types';
import { getList as getListCompany } from '../Company/services/company.services';
type Prop = {};

const Portal = (_: Prop) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loadingFetch, setLoadingFetch] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [portalForm, setPortalForm] = useState<PortalType>({});
  const [companyList, setCompanyList] = useState<CompanyType[]>([]);

  const { data: total } = useQuery('count', getCount);
  const {
    isLoading,
    isError,
    error,
    refetch,
    data: dataList,
  } = useQuery(
    ['portal-list', { skip: (page - 1) * pageSize, limit: pageSize, order: 'id desc' }],
    () => getList({ skip: (page - 1) * pageSize, limit: pageSize , order: 'id desc'})
  );
  
  useEffect(() => {
    getListCompany().then(res => setCompanyList(res));
  },[])
  if (isLoading) {
    return (
      <div>
        <Skeleton />
      </div>
    );
  }
  if (isError) {
    return <div>Error {error as any}</div>;
  }

  const setSelectedRowKeysEvent = (keys: number[]) => {
    setSelectedRowKeys(keys);
  };

  const onAddPortal = async () => {
    setLoadingFetch(true);
    await createPortal(portalForm);
    setFormValid(false);
    setPortalForm({});
    refetch();
    setLoadingFetch(false);
  };

  const onDeleteEvent = async (): Promise<void> => {
    setLoadingFetch(true);
    await deletePortals(selectedRowKeys);
    refetch();
    setLoadingFetch(false);
  };

  const onEditEvent = (): void => {
    const portal = dataList.find(
      (portal: PortalType) => portal.id === selectedRowKeys[0]
      );
    if (portal) {
      setPortalForm(portal);
    }
  };

  const onSaveEditEvent = async (): Promise<void> => {
    setLoadingFetch(true);
    await patchPortal(portalForm);
    setFormValid(false);
    setPortalForm({});
    refetch();
    setLoadingFetch(false);
  };

  const onCancelEditEvent = (): void => {
    setPortalForm({});
  };

  const uncheckAllEvent = (): void => {
    setSelectedRowKeys([]);
  };

  const setPaginationEvent = (data: { page: number; pageSize: number }) => {
    setPage(data.page);
    setPageSize(data.pageSize);
  };

  const portalFormChangeEvent = (form: PortalType): void => {
    setPortalForm(form);
    if (form.name !== '' && form.url !== '' && form.companyId) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  return (
    <div>
      <PortalModal portalForm={portalForm} formChange={portalFormChangeEvent} />
      {loadingFetch && <Spin />}
      {!loadingFetch && (
        <PortalActions
          formValid={formValid}
          selectedRowKeys={selectedRowKeys}
          onAddEvent={onAddPortal}
          onDeleteEvent={onDeleteEvent}
          onEditEvent={onEditEvent}
          onSaveEditEvent={onSaveEditEvent}
          onCancelEditEvent={onCancelEditEvent}
          uncheckAllEvent={uncheckAllEvent}
        />
      )}
      <PortalTable
        page={page}
        total={total}
        data={dataList}
        pageSize={pageSize}
        selectedRowKeys={selectedRowKeys}
        setPagination={setPaginationEvent}
        setSelectedRowKeys={setSelectedRowKeysEvent}
        companyList={companyList}
      />
    </div>
  );
};

export default Portal;
