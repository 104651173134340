import ApiService from "./api.service";
import JwtService from "./jwt.service";


const AuthenService = {
    logout(){
        JwtService.destroyToken();
        JwtService.destroyUserInfo();
    },
    login(userCredential: UserCredential) : Promise<boolean>{
        return new Promise( (resolve, reject) => {
            ApiService.post('/AppUsers/login',userCredential).then(function(res){
                ApiService.setAxiosGlobal(res.data.id);
                if(res.data.userId !== undefined && res.data.userId > 0){
                    ApiService.get('/AppUsers',res.data.userId).then(function(userData){
                        let userInfo = {
                            userId: userData.data.id,
                            username: userData.data.username,
                            roleId: userData.data.roleId,
                            isActive: userData.data.isActive,
                            email: userData.data.email,
                        }
                        JwtService.saveUserInfo(userInfo);
                        JwtService.saveToken(res.data.id);
                        resolve(true);
                    })
                }
            }).catch(err =>{
                resolve(false);
            })
        })
    }
}
export default AuthenService;
export class UserCredential {
    username: string;
    password: string;
  
    constructor() {
      this.username = '';
      this.password = '';
    }
  }