const ID_TOKEN_KEY = "id_token";
const USER_INFO = "userInfo";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getUserInfo = () => {
  return window.localStorage.getItem(USER_INFO);
};

export const saveUserInfo = userInfo => {
  window.localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
};

export const destroyUserInfo = () => {
  window.localStorage.removeItem(USER_INFO);
};

export const saveItem = (key, item) => {
  window.localStorage.setItem(key, JSON.stringify(item));
};

export const getItem = key => {
  return window.localStorage.getItem(key);
};

export const destroyItem = key => {
  window.localStorage.removeItem(key);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  saveUserInfo,
  destroyUserInfo,
  getUserInfo,
  saveItem,
  getItem,
  destroyItem
};
