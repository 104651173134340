import { getItemById } from '@/pages/Company/services/company.services';
import { Company } from '@/pages/Company/types';
import AppTable from '@/pages/components/AppTable';
import { Button, Input, InputRef, Space, TableColumnType } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import React, { useRef, useState } from 'react';
import { Portal } from '../types';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
type Prop = {
  total: number;
  page: number;
  pageSize: number;
  data: Record<string, any>[];
  selectedRowKeys?: React.Key[];
  setPagination: Function;
  setSelectedRowKeys: Function;
  companyList: Company[],
};
type DataIndex = keyof Portal;
const PortalTable = (props: Prop) => {
  const getCompanyNameById = (id:number) => {
    let temp = props.companyList.find(e => id === e.id);
    return temp?.name || null;
  }

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<Portal> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    // { title: 'ID', dataIndex: 'id' },
    {
      title: 'NAME',
      dataIndex: 'name',
      editable: true,
      ...getColumnSearchProps('name'),
    },
    { title: 'URL',
       dataIndex: 'url',
        editable: true 
      },
    { title: 'COMPANY', 
      dataIndex: 'companyId', 
      render: (value : number) => getCompanyNameById(value),
      editable: true },
  ];

  const setSelectedRowKeys = (keys: number[]) => {
    props.setSelectedRowKeys(keys);
  };

  return (
    <div>
      <AppTable
        columns={columns}
        data={props.data}
        page={props.page}
        total={props.total}
        pageSize={props.pageSize}
        selectedRowKeys={props.selectedRowKeys}
        setPagination={props.setPagination}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </div>
  );
};

export default PortalTable;
