import { Button, Input, message, Popconfirm, Space } from 'antd';
import React from 'react';

const { Search } = Input;

type Prop = {
  selectedRowKeys: React.Key[];
  doAction: Function;
};

const EditTableActions = (props: Prop) => {
  const editRow = () => {
    props.doAction('edit');
  };

  const confirmDelete = () => {
    props.doAction('DELETE');
  };
  const confirmBoldTitle = () => {
    props.doAction('BOLD_HEADER');
  };

  const cancelDelete = () => {
    message.error('Delete cancelled');
  };
  const cancelBoldTitle = () => {
    message.error('Bold Header cancelled');
  };

  const uncheckAll = () => {
    props.doAction('UNCHECK_ALL');
  };

  return (
    <div>
      {/*<div className="search-header">*/}
      {/*  <span className="label-name">Post:</span>*/}
      {/*  <Search style={{ width: 500 }} />*/}
      {/*</div>*/}

      <div className="mb-2 mt-2">
        <Space>
        {props.selectedRowKeys.length === 1 && (
            <Button type="default" onClick={editRow}>
              Edit
            </Button>
          )}
          {props.selectedRowKeys.length > 0 && (
            <Popconfirm
              title={`Are you sure delete ${props.selectedRowKeys.length} records?`}
              onConfirm={confirmDelete}
              onCancel={cancelDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger>Delete selected</Button>
            </Popconfirm>
          )}
          {props.selectedRowKeys.length > 0 && (
            <Popconfirm
              title={`Are you sure to bold header ${props.selectedRowKeys.length} records?`}
              onConfirm={confirmBoldTitle}
              onCancel={cancelBoldTitle}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary">Bold Header</Button>
            </Popconfirm>
          )}
          {props.selectedRowKeys.length > 0 && (
            <Button type="default" onClick={uncheckAll}>
              Uncheck All
            </Button>
          )}
        </Space>
      </div>
    </div>
  );
};

export default EditTableActions;
