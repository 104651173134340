import { Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tag } from '../types';
import ApiService from '@/services/api.service';
import { Category } from '@/types';

const StyledInput = styled(Input)`
    span.ant-input-group-addon {
      width: 140px
    }
`

type Prop = {
  tagForm: Tag;
  formChange: Function;
};

const TagModal = (props: Prop) => {
  const [categoryList, setCategoryList] = useState<Category[]>([]);

  const handleOnChange = (event: any, type: 'name') => {
    const value = event.target.value;
    props.formChange({ ...props.tagForm, [type]: value });
  };
  const handleOnChangeSelect = (value) => {
    props.formChange({ ...props.tagForm, categoryString: value});
  }
  useEffect(() => {
    ApiService.get('/Categories').then(
      (res) => res.data
    ).then(res => setCategoryList(res));
  }, []);
  return (
    <div>
      <StyledInput
        addonBefore={'name'}
        value={props.tagForm?.name}
        onChange={(event) => handleOnChange(event, 'name')}
        style={{marginBottom: 6}}
      />
       <Select
                style={{ width: '100%', paddingLeft: '140px',  }}
                value={props.tagForm?.categoryString}
                showSearch
                filterOption={(input, option) => (option?.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())}
                placeholder="Please select category"
                onChange={handleOnChangeSelect}
                options={[{value: '', label: '--Select Category--'},...categoryList.map(e => {
                  return {
                    value: e.slug,
                    label: e.name
                  }
                })]}
          />
     
    </div>
  );
};

export default TagModal;
