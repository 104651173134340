import { Skeleton, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import TagActions from './components/tag-actions';
import TagModal from './components/tag-modal';
import TagTable from './components/tag-table';
import {
  createTag,
  deleteTags,
  getCount,
  getList,
  patchTag,
} from './services/tag.services';
import { Tag as TagType } from './types';
type Prop = {};

const Tag = (_: Prop) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loadingFetch, setLoadingFetch] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [tagForm, setTagForm] = useState<TagType>({});

  const { data: total } = useQuery('count', getCount);
  const {
    isLoading,
    isError,
    error,
    refetch,
    data: dataList,
  } = useQuery(
    ['tag-list', { skip: (page - 1) * pageSize, limit: pageSize, order: 'id desc' }],
    () => getList({ skip: (page - 1) * pageSize, limit: pageSize , order: 'id desc', where: {
      slug:  {regexp:/^(?!(t|d)[0-9])/} // filter bỏ tag begin with t/d[0-9]
    }})
  );
  
  useEffect(() => {
//
  },[])
  if (isLoading) {
    return (
      <div>
        <Skeleton />
      </div>
    );
  }
  if (isError) {
    return <div>Error {error as any}</div>;
  }

  const setSelectedRowKeysEvent = (keys: number[]) => {
    setSelectedRowKeys(keys);
  };

  const slugify = (str: string)  => {
    return String(str)
      .normalize('NFKD') 
      .replace(/[\u0300-\u036f]/g, '') 
      .trim() 
      .toLowerCase() 
      .replace(/[^a-z0-9 -]/g, '') 
      .replace(/\s+/g, '-') 
      .replace(/-+/g, '-'); 
  }

  const onAddTag = async () => {
    tagForm.slug = slugify(tagForm.name);
    tagForm.count = 1;
    console.log('viet30',tagForm);
    setLoadingFetch(true);
    await createTag(tagForm);
    setFormValid(false);
    setTagForm({});
    refetch();
    setLoadingFetch(false);
  };

  const onDeleteEvent = async (): Promise<void> => {
    setLoadingFetch(true);
    await deleteTags(selectedRowKeys);
    refetch();
    setLoadingFetch(false);
  };

  const onEditEvent = (): void => {
    const tag = dataList.find(
      (tag: TagType) => tag.id === selectedRowKeys[0]
      );
    if (tag) {
      setTagForm(tag);
    }
  };

  const onSaveEditEvent = async (): Promise<void> => {
    setLoadingFetch(true);
    tagForm.slug = slugify(tagForm.name);
    await patchTag(tagForm);
    setFormValid(false);
    setTagForm({});
    refetch();
    setLoadingFetch(false);
  };

  const onCancelEditEvent = (): void => {
    setTagForm({});
  };

  const uncheckAllEvent = (): void => {
    setSelectedRowKeys([]);
  };

  const setPaginationEvent = (data: { page: number; pageSize: number }) => {
    setPage(data.page);
    setPageSize(data.pageSize);
  };

  const tagFormChangeEvent = (form: TagType): void => {
    setTagForm(form);
    if (form.name && form.categoryString) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  return (
    <div>
      <TagModal tagForm={tagForm} formChange={tagFormChangeEvent} />
      {loadingFetch && <Spin />}
      {!loadingFetch && (
        <TagActions
          formValid={formValid}
          selectedRowKeys={selectedRowKeys}
          onAddEvent={onAddTag}
          onDeleteEvent={onDeleteEvent}
          onEditEvent={onEditEvent}
          onSaveEditEvent={onSaveEditEvent}
          onCancelEditEvent={onCancelEditEvent}
          uncheckAllEvent={uncheckAllEvent}
        />
      )}
      <TagTable
        page={page}
        total={total}
        data={dataList}
        pageSize={pageSize}
        selectedRowKeys={selectedRowKeys}
        setPagination={setPaginationEvent}
        setSelectedRowKeys={setSelectedRowKeysEvent}
      />
    </div>
  );
};

export default Tag;
