import ApiService from '@/services/api.service';
import AuthenService, { UserCredential } from '@/services/authen.service';
import JwtService from '@/services/jwt.service';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from './components/logo';
import {cms_version} from '../../../config';

const Login = () => {
  ApiService.init();

  const navigate = useNavigate();

  const userInfo = JwtService.getUserInfo();

  const onFinish = (value: any) => {
    const key = 'proceed_to_login';
    message.loading({ content: 'Loading - Please wait...', key });

    let info = new UserCredential();
    info.username = value.username;
    info.password = value.password;

    AuthenService.login(info).then((resolve) => {
      if (resolve) {
        message.success({
          content: 'Logged in successfully!',
          key,
          duration: 2,
        });
        navigate('/admin');
      } else {
        message.error({
          content: 'OOPS! Please check your login information.',
          key,
          duration: 3,
        });
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {};

  useEffect(() => {
    if (userInfo) {
      ApiService.setAxiosGlobal(`${JwtService.getToken()}`);
      navigate('/admin');
    }
  });

  return (
    <>
      <div className="layout-center">
        <Logo />
        <div className="wrap-login">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Username"
                prefix={<UserOutlined />}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input
                type={'password'}
                size="large"
                placeholder="Password"
                prefix={<LockOutlined />}
              />
            </Form.Item>

            {/* <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{ span: 24 }}
            >
              <Checkbox className="check-form">Remember me</Checkbox>
            </Form.Item> */}

            <Form.Item wrapperCol={{ offset: 10, span: 24 }}>
              <Button type="primary" htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div>Version: {cms_version}</div>
      </div>
    </>
  );
};

export default Login;
