import { Button } from 'antd';
import React, { useState } from 'react';

type Prop = {
  onAddEvent: Function;
  onDeleteEvent: Function;
  onEditEvent: Function;
  onSaveEditEvent: Function;
  onCancelEditEvent: Function;
  selectedRowKeys: number[];
  uncheckAllEvent: Function;
  formValid: boolean;
};

const PortalActions = (props: Prop) => {
  const [editButtonLabel, setEditButtonLabel] = useState<string>('Edit');

  const addPortal = (): void => {
    props.onAddEvent();
  };

  const deletePortal = (): void => {
    props.onDeleteEvent();
  };

  const editPortal = (): void => {
    if (editButtonLabel === 'Edit') {
      setEditButtonLabel('Save Edit');
      props.onEditEvent();
      return;
    }
    if (editButtonLabel === 'Save Edit') {
      setEditButtonLabel('Edit');
      props.onSaveEditEvent();
    }
  };

  const cancelEditPortal = (): void => {
    setEditButtonLabel('Edit');
    props.onCancelEditEvent();
  };

  const uncheckAll = (): void => {
    props.uncheckAllEvent();
  };

  return (
    <div className="portal-actions">
      {props.selectedRowKeys.length > 0 && (
        <Button onClick={uncheckAll}>Uncheck All</Button>
      )}
      {props.formValid && <Button onClick={addPortal}>Add</Button>}
      {props.selectedRowKeys.length > 0 && (
        <Button onClick={deletePortal}>Delete</Button>
      )}
      {props.selectedRowKeys.length === 1 && (
        <Button onClick={editPortal}>{editButtonLabel}</Button>
      )}
      {editButtonLabel === 'Save Edit' && (
        <Button onClick={cancelEditPortal}>Cancel Edit</Button>
      )}
    </div>
  );
};

export default PortalActions;
