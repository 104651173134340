import React from "react";
import AppTable from "@/pages/components/AppTable";
import { Button, Space } from "antd";

type Prop = {
    page: number;
    total: number;
    data: any;
    pageSize: number;
    setPagination: (data: {
        page: number;
        pageSize: number;
    }) => void;
    deleteEvt: (id: number) => void;
    viewEvt: (record: any) => void;
};

const getRoleName = (roleId: number): string => {
    switch (roleId) {
        case 1:
            return 'ROOT';
        case 2:
            return 'ADMIN';
        case 3:
            return 'USER';
        default:
           return '';
    }
};

const AccountTable = (props: Prop) => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'realm',
            key: 'realm',
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'roleId',
            key: 'roleId',
            render: (_, record: any) => <>
                <span>{ getRoleName(record.roleId) }</span>
            </>
        },
        {
            title: 'Active',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => <>
                <span>{ record.isActive ? 'YES' : 'NO' }</span>
            </>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record: any) =>
                <Space>
                    <Button type="primary" onClick={() => props.viewEvt(record)}>View</Button>
                    <Button type="primary" danger disabled={ [1, 2].includes(record.roleId) } onClick={() => props.deleteEvt(record.id)}>Delete</Button>
                </Space>
            ,
        },
    ];

    return (
        <div>
            <AppTable
                columns={columns}
                data={props.data}
                page={props.page}
                total={props.total}
                pageSize={props.pageSize}
                setPagination={props.setPagination}
            />
        </div>
    );
}

export default AccountTable;