import { Button } from 'antd';
import React, { useState } from 'react';

type Prop = {
    onAddEvent: Function;
    onDeleteEvent: Function;
    onEditEvent: Function;
    onSaveEditEvent: Function;
    onCancelEditEvent: Function;
    selectedRowKeys: number[];
    uncheckAllEvent: Function;
    formValid: boolean;
}
const CompanyActions : React.FC<Prop> = (props: Prop) => {
  const [editButtonLabel, setEditButtonLabel] = useState<string>('Edit');
    
    const addCompany = (): void => {
        props.onAddEvent();
      };
    
      const deleteCompany = (): void => {
        props.onDeleteEvent();
      };
    
      const editCompany = (): void => {
        if (editButtonLabel === 'Edit') {
          setEditButtonLabel('Save Edit');
          props.onEditEvent();
          return;
        }
        if (editButtonLabel === 'Save Edit') {
          setEditButtonLabel('Edit');
          props.onSaveEditEvent();
        }
      };
    
      const cancelEditCompany = (): void => {
        setEditButtonLabel('Edit');
        props.onCancelEditEvent();
      };
    const uncheckAll = (): void => {
        props.uncheckAllEvent();
      };
    return (
        <div className="company-actions">
        {props.selectedRowKeys.length > 0 && (
          <Button onClick={uncheckAll}>Uncheck All</Button>
        )}
        {props.formValid && <Button onClick={addCompany}>Add</Button>}
        {props.selectedRowKeys.length > 0 && (
          <Button onClick={deleteCompany}>Delete</Button>
        )}
        {props.selectedRowKeys.length === 1 && (
          <Button onClick={editCompany}>{editButtonLabel}</Button>
        )}
        {editButtonLabel === 'Save Edit' && (
          <Button onClick={cancelEditCompany}>Cancel Edit</Button>
        )}
      </div>
    )
}
export default CompanyActions;