import CardViewListItem from '@/pages/ViewAndPush/components/CardView/card-view-list-item';
import { ContentHub } from '@/types';
import { Col, Pagination, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const CustomRow = styled(Row)`
  & {
    width: 100%;
  }
  .ant-row {
    width: 100%;
    margin: 0 !important;
  }
`;

type Prop = {
  data: ContentHub[];
  page: number;
  total: number;
  pageSize: number;
  selectedRowKeys: React.Key[];
  setPagination: Function;
  setSelectedRowKeys: Function;
  viewDetailEvent: (id: number) => void;
};

const CardViewList = (props: Prop) => {
  const [cardData, setCardData] = useState<ContentHub[]>([]);

  const clickCardEvent = (id: number) => {
    if (props.selectedRowKeys.includes(id)) {
      props.setSelectedRowKeys(
        props.selectedRowKeys.filter((item) => item !== id)
      );
    } else {
      props.setSelectedRowKeys([...props.selectedRowKeys, id]);
    }
  };

  const onPaginationChange = (page: number, pageSize: number) => {
    props.setPagination({ page, pageSize });
  };

  useEffect(() => {
    const dataWithPagi = props.data.slice((props.page - 1) * props.pageSize, props.page * props.pageSize);
    setCardData(dataWithPagi);
  }, [props.data, props.page, props.pageSize])

  return (
    <>
      <section className="list-card pt-3">
        <CustomRow>
          <Pagination
            // defaultCurrent={1}
            current={props.page}
            // defaultPageSize={20}
            pageSize={props.pageSize}
            total={props.total}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            onChange={onPaginationChange}
          />
          <Row gutter={[10, 20]}>
            {cardData.map((item) => (
              <Col span={6} key={item.id}>
                <CardViewListItem
                  data={item}
                  selectedRowKeys={props.selectedRowKeys}
                  clickCardEvent={clickCardEvent}
                  viewDetailEvent={props.viewDetailEvent}
                />
              </Col>
            ))}
          </Row>
        </CustomRow>
      </section>
    </>
  );
};
export default CardViewList;
