import { Collapse, Image, Table, TableColumnsType } from 'antd';
import React from 'react';
import { DuplicateContentPortals } from '../types';
import { fallbackImage } from './fallback-image';

type Prop = {
    postsCanPush: DuplicateContentPortals[];
    postsExists: DuplicateContentPortals[];
}

interface ExpandedDataType {
    key: React.Key;
    portal: string;
    total: number;
    language: string[];
}

const { Panel } = Collapse;

const columns: TableColumnsType<DuplicateContentPortals> = [
    {
        title: 'TITLE_EN',
        dataIndex: 'title_en',
        key: 'title_en',
        width: '280px',
    },
    {
        title: 'IMAGE',
        dataIndex: 'image',
        render: (text, record, index) => {
            return <Image width={100} height={100} src={text} fallback={fallbackImage} />
        },
        width: '180px',
    },
    {
        title: 'CATEGORY',
        dataIndex: 'category',
        width: '120px',
    },
    {
        title: 'GENDER', dataIndex: 'gender',
        width: '120px'
    },
    {
        title: 'TAGS', dataIndex: 'tags',
        width: '180px'
    },
    {
        title: 'USED', dataIndex: 'used_count',
    }
];

const ViewPushShowDuplicate = (props: Prop) => {
    return <>
        <Collapse defaultActiveKey={['1']}>
            <Panel header={"1. Posts allow to Push <" + props.postsCanPush?.length + " posts >"} key="1">
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={props.postsCanPush}
                    size="small"
                />
            </Panel>
            <Panel header={"2. Posts existed in these seleted portals <" + props.postsExists.length + " posts >"} key="2">
                <Table
                    rowKey="id"
                    columns={columns}
                    expandable={{
                        expandedRowRender: (record: DuplicateContentPortals) => {
                            return <>
                                <p>This post pushed to these portals below : </p>

                                <ul>
                                    {
                                        record.usedPortals && record.usedPortals?.map((item, index) => <li key={index}>{item}</li>)
                                    }
                                </ul>
                            </>
                        }, defaultExpandAllRows: true
                    }}
                    dataSource={props.postsExists}
                    size="small"
                />
            </Panel>
        </Collapse>
    </>
}

export default ViewPushShowDuplicate;