import React, { useState } from 'react';
import { Form, Table, Image, Typography, Space, Button, message, Input, Popconfirm } from 'antd';
import { fallbackImage } from '@/pages/ViewAndPush/components/fallback-image';
import { ContentHub } from '@/types';

type Prop = {
    data: ContentHub[];
    total: number;
    viewDetailEvt: (item: ContentHub) => void;
}

const PushHistoryDetailPosts = (props: Prop) => {
    const columns = [
        {
            title: 'TITLE_EN',
            dataIndex: 'title_en',
            editable: true,
            key: 'title_en',
            width: '280px',
        },
        {
            title: 'IMAGE',
            dataIndex: 'image',
            editable: false,
            render: (text, record, index) => {
                return <Image width={100} height={100} src={text} fallback={fallbackImage} />
            },
            width: '180px',
        },
        {
            title: 'CATEGORY',
            dataIndex: 'category',
            editable: true,
            width: '120px',
        },
        {
            title: 'GENDER', dataIndex: 'gender', editable: true,
            width: '120px'
        },
        {
            title: 'TAGS', dataIndex: 'tags', editable: true,
            width: '180px'
        },
        {
            title: 'USED', dataIndex: 'used_count', editable: true,
        },
        {
            title: 'ACTION',
            key: 'action',
            render: (record) => (
                <Button type='primary' onClick={() => props.viewDetailEvt(record)}>
                    View Detail
                </Button>
            ),
        },
    ];

    return (
        <Table
            rowKey="id"
            bordered
            dataSource={props.data}
            columns={columns}
            rowClassName="editable-row"
            // rowSelection={rowSelection}
            pagination={{
                //   position: ['bottomLeft', 'topLeft'],
                //   current: props.page,
                //   pageSize: props.pageSize,
                total: props.total,
                //   onChange: (page, pageSize) => {
                //     props.setPagination({ page, pageSize });
                //   },
                defaultPageSize: 5,
                // showSizeChanger: true, 
                // pageSizeOptions:['5','10','20','30','50','100','200'],
            }}
        // scroll={{ y: 840, x: 4000 }}
        />
    )
}

export default PushHistoryDetailPosts;
