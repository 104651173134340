import { Filter } from './../../../types';
import axios from 'axios';

type Cart = {
    id?: number;
    items: string;
    userId: number;
}

export const getCarts = async (filter: Filter = {}) => {
    try {
        const { data } = await axios.get('/Carts', {
            params: {
                filter,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const findCart = async (filter: Filter = {}) => {
    try {
        const { data } = await axios.get('/Carts/findOne', {
            params: {
                filter,
            },
        });
        return data;
    } catch (error) {
        // throw new Error(error);
        return {}
    }
}

export const getCartCount = async () => {
    try {
        const { data } = await axios.get('/Carts/count', {
            params: {
                where: {},
            },
        });
        return data?.count || 0;
    } catch (error) {
        throw new Error(error);
    }
};

export const deleteCartsByIds = async (ids: number[]) => {
    try {
        await Promise.all(ids.map((id: number) => axios.delete(`/Carts/${id}`)));
    } catch (error) {
        throw new Error(error);
    }
};

export const createCart = async (cart: Cart) => {
    try {
        const { data } = await axios.post('/carts', cart);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const patchCart = async (items: number[], id: number) => {
    try {
        const cart = {
            id,
            items: JSON.stringify(items),
        };
        const { data } = await axios.patch(`/carts/${cart.id}`, cart);
        return data;
    } catch (error) {
        throw new Error(error);
    }
}