import Logo from '@/layouts/components/logo';
import Header from '@/pages/Header/header';
import routes from '@/routes';
import JwtService from '@/services/jwt.service';
import {TagsOutlined, UserOutlined, ConsoleSqlOutlined, FileSearchOutlined, FormOutlined, HddOutlined, HistoryOutlined, UnorderedListOutlined, ToolOutlined} from '@ant-design/icons'
import { Layout, Menu, MenuProps } from 'antd';
import React, { useEffect } from 'react';
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';
const { Content, Sider } = Layout;

const UserLayout = () => {
  const location = useLocation();

  const navigate = useNavigate();

  let userInfo = JSON.parse(`${JwtService.getUserInfo()}`);
  if (!userInfo) userInfo = {};

  useEffect(() => {
    if (!userInfo.username) {
      navigate('/');
    }
  }, []);

  // tìm menu item đang chọn từ pathname khớp với routes, sài cứng routes[1]
  const matchPaths =
    routes[1]?.children?.filter((r) => {
      const routePath = r.index ? '/' : r.path ?? '/';
      return matchPath(routePath, location.pathname);
    }) || [];

  let matchMenuKeys: string[] = [];
  if (matchPaths.length > 0) {
    if (matchPaths[0].index) matchMenuKeys = ['/'];
    else if (matchPaths[0].path) matchMenuKeys = [matchPaths[0].path];
  }

  const menuItems: MenuProps['items'] = [
    {
      key: '/',
      icon: <FormOutlined />,
      label: `Fill in DB`,
      onClick: () => navigate('/admin'),
    },
    {
      key: '/view-and-push',
      icon: <FileSearchOutlined />,
      label: `View & Push`,
      onClick: () => navigate('view-and-push'),
    },
    // {
    //   key: '/post/:id',
    //   icon: <HomeOutlined />,
    //   label: `Posts`,
    //   onClick: () => navigate('/post/1'),
    // },
    {
      key: '/edit-database',
      icon: <ConsoleSqlOutlined />,
      label: `Edit DB`,
      onClick: () => navigate('edit-database'),
    },
    {
      key: '/company',
      icon: <HddOutlined />,
      label: `Company`,
      onClick: () => navigate('company'),
    },
    {
      key: '/portal',
      icon: <UnorderedListOutlined />,
      label: `Portal`,
      onClick: () => navigate('portal'),
    },
    {
      key: '/tag',
      icon: <TagsOutlined />,
      label: `Tag`,
      onClick: () => navigate('tag'),
    },
    {
      key: '/push-history',
      icon: <HistoryOutlined />,
      label: `Push History`,
      onClick: () => navigate('push-history'),
    },
    {
      key: '/account',
      icon: <UserOutlined />,
      label: `Account`,
      onClick: () => navigate('account'),
    },
    {
      key: '/tools',
      icon: <ToolOutlined />,
      label: `Tools`,
      onClick: () => navigate('tools'),
    },
  ];

  return (
    <>
      <Layout>
        <Sider
          theme="light"
          style={{
            overflow: 'hidden',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <Logo />
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={['menuHome']}
            items={menuItems}
            selectedKeys={matchMenuKeys}
            style={{
              height: 'calc(100vh - 64px)',
              overflowX: 'hidden',
              overflowY: 'auto',
              backgroundColor: '#96D2F9',
            }}
          />
        </Sider>
      </Layout>
      <Layout style={{ marginLeft: 200, overflow: 'hidden' }}>
        <Header />
        <Content
          style={{
              marginTop: 72,
              marginLeft: 8,
              width: 'calc(100vw - 216px)',
              height: 'calc(100vh - 80px)',
              overflow: 'auto',
          }}
        >
          <div className="layout-content">
            <Outlet />
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default UserLayout;
