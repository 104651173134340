import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  notification,
  Popconfirm,
  Select,
  Spin,
  Modal,
} from "antd";
import excel2Json from "@/util/excel2json";
import styled from "styled-components";
import { getList } from "@/pages/Edit/services/edit-database";
import {
  upsertToWP,
} from "@/pages/ViewAndPush/services/wordpress.services";
import * as XLSX from "xlsx";
const { TextArea } = Input;
const { Option } = Select;

const BulkUpsert = () => {
  const [processMessage, setProcessMessage] = useState("");
  const [listPortals, setListPortals] = useState("");
  const [listPosts, setListPosts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>([]);

  const onChange = async (e) => {
    const data = await excel2Json(e);
    const newData = data.map((e) => e[0]);
    setListPortals(newData.join(",\n"));
  };

  const onChangeRow = (value) => {
    setListPortals(value);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchDataList = async () => {
    const result = await getList({
      order: "title_en",
    });
    setDataList(result);
  };

  const handleChange = (value) => {
    setListPosts(value);
  };
 // Tốc độ chậm!!!
  // const bulkUpsertPostOnPortals = async (posts, portals) => {
  //   const uniquePortals = [...new Set(portals)]
  //     .filter((e) => e)
  //     .map((e) => (e.endsWith("/") ? e.slice(0, -1) : e));
  //   const results: any = [];
  //   try {
  //     for (const post of posts) {
  //       for (const portal of uniquePortals) {
  //         try {
  //           let res = await upsertToWP(
  //             { post },
  //             `${portal}/wp-json/ccpplugin/v1/update_post`
  //           );
  //           results.push({
  //             portal,
  //             post,
  //             status: res.status,
  //             message: res.message,
  //           });
  //         } catch (err) {
  //           results.push({
  //             portal,
  //             post,
  //             status: "failed",
  //             error: err.message,
  //           });
  //         }
  //         setResults([...results]); // Cập nhật trạng thái sau mỗi lần xử lý
  //         // await new Promise((resolve) => setTimeout(resolve, 500)); // Thêm độ trễ để người dùng có thể thấy kết quả
  //       }
  //     }
  //     notification.success({ message: "Bulk upsert completed" });
  //   } catch (err) {
  //     console.error("Error upsert post", err);
  //     notification.error({
  //       message: "Bulk upsert failed",
  //       description: err.message,
  //     });
  //   }
  // };

  const bulkUpsertPostOnPortals = async (posts, portals) => {
    const uniquePortals = [...new Set(portals)]
      .filter((e) => e)
      .map((e: any) => (e.endsWith("/") ? e.slice(0, -1) : e));
    const results: any = [];
    try {
      for (const post of posts) {
        const postResults = await Promise.all(
          uniquePortals.map(async (portal) => {
            try {
              let res = await upsertToWP(
                { post },
                `${portal}/wp-json/ccpplugin/v1/update_post`
              );
              return { portal, post, status: res.status, message: res.message };
            } catch (err) {
              return { portal, post, status: "failed", error: err.message };
            }
          })
        );
        results.push(...postResults);
        setResults(results);
      }
      notification.success({ message: "Bulk upsert completed" });
    } catch (err) {
      console.error("Error upsert post", err);
      notification.error({
        message: "Bulk upsert failed",
        description: err.message,
      });
    }
  };

  const handleConfirmOk = async () => {
    setLoading(true);
    let portals = listPortals.split(",");
    // xóa khoảng trắng, xóa ký tự xuống dòng, thêm https:// nếu chưa có.
    portals = portals.map(e => {
      e = e.trim().replace(/\n/g, '');
      return e.startsWith('https://') ? e : 'https://' + e;
    });
    const posts = listPosts.map((e) => JSON.parse(e));
    setResults([]);
    setIsModalOpen(true);
    await bulkUpsertPostOnPortals(posts, portals);
    setLoading(false);
  };

  const exportToExcel = () => {
    let formatResults = results.map(e=> ({post: e.post.title_en, portal : e.portal, status: e.status, message: e.message}));
    const worksheet = XLSX.utils.json_to_sheet(formatResults);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Results");
    XLSX.writeFile(workbook, "BulkUpsertResults.xlsx");
};

  useEffect(() => {
    fetchDataList();
  }, []);

  return (
    <div>
      <TitleStyle>List Portals:</TitleStyle>
      Upload Excel
      <br />
      <input
        type="file"
        onChange={onChange}
        style={{ paddingTop: 8, paddingBottom: 8 }}
      />
      <TextArea
        rows={15}
        value={listPortals}
        onChange={(e) => onChangeRow(e.target.value)}
      />
      <br />
      <br />
      <TitleStyle>List Posts:</TitleStyle>
      <Select
        placeholder="Select Posts | Category | Gender"
        mode="multiple"
        allowClear
        onChange={handleChange}
        style={{ width: 800 }}
      >
        {dataList.map((item: any) => (
          <Option key={item.content_id} value={JSON.stringify(item)}>
            {`${item.title_en} | ${item.category} | ${item.gender}`}
          </Option>
        ))}
      </Select>
      <br />
      <br />
      <Popconfirm
        title="Are you sure to start bulk upsert?"
        onConfirm={handleConfirmOk}
        onCancel={() => {}}
        okText="Yes"
        cancelText="No"
      >
        <Button type="primary" disabled={loading}>
          {loading ? <Spin /> : "Start Bulk Upsert"}
        </Button>
      </Popconfirm>
      <Modal
        width="auto"
        forceRender
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
           <Button key="export" onClick={exportToExcel}>
              Export to Excel
          </Button>,
        ]}
        title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",marginRight:"30px" }}>
          <span>Bulk Upsert Results</span>
          <Button key="export" onClick={exportToExcel}>
          Export to Excel
          </Button>
          </div>
        }
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <div>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  Post
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  Portal
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  Messages
                </th>
              </tr>
            </thead>
            <tbody>
              {results.map((result: any, index) => (
                <tr key={index}>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "left",
                    }}
                  >
                    {result.post.title_en}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "left",
                    }}
                  >
                    {result.portal}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "center",
                      color: result.status == "200" ? "green" : "red",
                    }}
                  >
                    {result.status}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "left",
                    }}
                  >
                    {result.status === "failed" ? (
                      <>
                        <strong>Error:</strong> {result.error}
                      </>
                    ) : (
                      <>
                        <span
                          style={{
                            color: result.status == "200" ? "green" : "red",
                          }}
                        >
                          {result.message}
                        </span>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {loading && <Spin />}
        </div>
      </Modal>
    </div>
  );
};

export default BulkUpsert;

const TitleStyle = styled.h1`
  font-size: 1.5em;
  color: palevioletred;
`;
