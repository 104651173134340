import React from 'react';
import AppBreadcrumb from './components/breadcrumb';
import Logout from './components/logout';
import styled from 'styled-components';

const HeaderTag = styled.header`
  & {
    overflow: hidden;
    padding: 0 1rem;
    margin: 0;
    height: 64px;
    background-color: white;
    position: fixed;
    z-index: 1;
    width: calc(100vw - 200px);
    align-items: center;
    border-bottom: 1px solid rgb(240, 240, 240);
  }
  .ant-breadcrumb .ant-breadcrumb-link {
    display: flex;
    align-items: center;
  }
  .ant-breadcrumb li {
    display: flex;
    align-items: center;
  }
`;

const Header = () => {
  return (
    <>
        {/* <header
          style={{
            overflow: 'hidden',
            paddingLeft: '1rem',
            margin: 0,
            height: 64,
            backgroundColor: 'white',
            position: 'fixed',
            zIndex: 1,
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid rgb(240, 240, 240)',
          }}
        >
            <AppBreadcrumb />
            <Logout />
        </header> */}
        <HeaderTag className='d-flex justify-content-between'>
            {/*<AppBreadcrumb />*/}
            <div></div>
            <Logout />
        </HeaderTag>
    </>
  );
};

export default Header;
